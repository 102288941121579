import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router,Routes,Route,Navigate} from 'react-router-dom';
import Nav from './component/MyUICompo/Nav'
import Nav2 from './component/MyUICompo/Nav2'
import Login from './component/Login'
import TableCompo from './component/TableCompo';
import ChartCompo from './component/ChartCompo';
import Footer from './component/MyUICompo/Footer';
import RegionDataCompo from './component/RegionDataCompo';
import CagrBoxCompo from './component/CagrBoxCompo'
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  
   <Nav2 />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
