import React, { useState } from 'react'


class CagrBoxCompo extends React.Component {
   
  state = {data:'',title:'',staticcagr:''};

componentWillMount() {
  
  this.calldata();
}
calldata(){
    
  const dataa=this.props.cagerdata;
  console.log(dataa.tabledata.length);
  let listItems = dataa.tabledata.map((item, index, dataarray) => {
  
    
    return(

         <div className="col-lg-4 d-flex grid-margin stretch-card">
             <div className="card sale-diffrence-border">
               <div className="card-body">
                 <h2 className="text-dark mb-2 font-weight-bold">{dataa.cagr[index]}</h2>
                 <h4 className="card-title mb-2">{dataarray[index].name}</h4>
                 <small className="text-muted">CAGR {dataa.cagryear}</small>
               </div>
             </div>
           </div>
       
    )
    
  })
  
  this.setState({
    data: listItems,
    title:dataa.Heading.substring(dataa.Heading.indexOf("Estimates & Forecast,") + 21),
    staticcagr:dataa.cagryear
  });
   
}
  render() {
   
    return (
        <>
        <div className="row">
           
           {this.state.data}
           <div className="col-lg-4 d-flex grid-margin stretch-card">
             <div className="card sale-visit-statistics-border">
               <div className="card-body">
                 <h2 className="text-dark mb-2 font-weight-bold">{this.props.name}</h2>
                 <h4 className="card-title mb-2">{this.state.title}</h4>
                 <small className="text-muted">CAGR ({this.state.staticcagr})</small>
               </div>
             </div>
           </div>
         </div>
        </>
    );
    

  }

}
 
export default CagrBoxCompo;