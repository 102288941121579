import React, { useState, useEffect } from "react";
import { getRegionDataByID, getCountryDataByID } from "../api-services/AllAPI";

const MonthAndWeekCompo = (props) => {
  let data_extract = "";
  let cagr_data = "";
  let rtitle;
  let response;
  let [monthdata, setmonthdata] = useState();
  let [weekdata, setweekdata] = useState();

  let [singlemonth, setsinglemonth] = useState();
  let [singleweek, setsingleweek] = useState();

  useEffect(() => {
    async function callbar() {
      if (props.countryID) {
        response = await getCountryDataByID(props.countryID);
      } else {
        response = await getRegionDataByID(props.resgionID);
      }

      if (props.countryID) {
        data_extract = response.coutrydata;
        rtitle = response.coutrydata[0].Heading;
      } else {
        data_extract = response._RegionData;
        cagr_data = response._RegionData[0];
        rtitle = cagr_data.Heading;
      }
      const montharray = [];
      const weekarray = [];
      let flag = 0;
      data_extract.map((rdata1, index) => {
        if (props.prodtype === rdata1.product) {
          montharray[flag] = rdata1.pmonth;
          weekarray[flag] = rdata1.week;
          flag = flag + 1;
        }
      });

      const monthupdatedata = [...new Set(montharray)];
      const weekupdatedata = [...new Set(weekarray)];
      setmonthdata(monthupdatedata);
      setweekdata(weekupdatedata);
    }
    callbar();
  }, [props.countryID, props.prodtype]);

 
  const handleOnWeekChnage = (event) => {
    //setSelected(event.target.value);
    setsingleweek(event.target.value);
    let monthweekdata = {
      month: singlemonth,
      week: event.target.value,
    };
    props.ondropdownselect(monthweekdata);
  };

  const handleOnMonth = (event) => {
    setsinglemonth(event.target.value);
    let monthweekdata = {
      month: event.target.value,
      week: singleweek,
    };
    //setSelected(event.target.value);
    props.ondropdownselect(monthweekdata);
  };

  if (!monthdata && !weekdata) return "";

  return (
    <>
                <div className="row">
                  <div class="col-md-6 grid-margin">
                  <div class="form-group">
                    <h4 class="card-title">Select Month</h4>
                    <select
                      class="js-example-basic-single w-100"
                      onChange={handleOnMonth}
                    >
                      <option value="Select Month">Select Month</option>

                      {monthdata.map((monthname) => {
                        return (
                          <option key={monthname} value={monthname}>
                            {monthname}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  </div>
                  <div class="col-md-6 grid-margin">
                  <div class="form-group">
                    <h4 class="card-title">Select Week</h4>
                    <select
                      class="js-example-basic-single w-100"
                      onChange={handleOnWeekChnage}
                    >
                      <option value="Select Week">Select Week</option>
                      {weekdata.map((weekdata) => {
                        return (
                          <option key={weekdata} value={weekdata}>
                            Week {weekdata}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  </div>
                </div>
    </>
  );
};

export default MonthAndWeekCompo;
