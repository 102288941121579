import {useState} from 'react'
import PropTypes from 'prop-types';
//import { loginUser, matchtoken } from "../api-services/AllAPI";
 

async function loginUser(credentials) {
  
  return fetch('http://137.184.238.167:9000/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data =>  data.json());
    //return "welcome";
 }

 async function matchtoken (token){
  return fetch('https://api.gmipulse.in/login/me', {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'token': token
  }
})
  .then(userdata =>  userdata.json());
}

 export default function Login({ setToken }) {
  const [emailid, setUserName] = useState();
  const [password, setPassword] = useState();
  const [message, setMessage] = useState();
 
  const handleSubmit = async e => {
    e.preventDefault();
   
    const response = await loginUser({
      emailid,
      password
    });

   //console.log(response.token);
    if(response.token)
    {
      
      const chktoken = await matchtoken(response.token)
      //console.log(chktoken);
      if(chktoken.emailid)
      {
          setToken(response);
      }
      else{
        setMessage('Token Does not Match');
        //setToken('');
      }
      //setMessage(message)
    }else{
      setMessage(response.message);
    }
  }
  
  const style1 = {backgroundColor: 'antiquewhite'};
  const style2 = {padding: "10px"};
  const style3 = {color: "red"}; 
 return(

      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div className="row flex-grow">
              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <div className="auth-form-transparent text-left p-3">
                  <div className="brand-logo">
                    <img src="../../myCSS/report/gmipulse.png" alt="logo"/>
                  </div>
                  <h4>Welcome back!</h4>
                  <h6 className="font-weight-light">Happy to see you again!</h6>
                  <form className="pt-3" onSubmit={handleSubmit}>
                   
                  <p style={{...style1, ...style2,...style3 }}>{message}</p>
                    
                    <div className="form-group">
                      <label for="exampleInputEmail">Username</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="mdi mdi-account-outline text-primary"></i>
                          </span>
                        </div>
                        <input type="text" className="form-control form-control-lg border-left-0" 
                        name="emailid" id="exampleInputEmail" placeholder="Username" onChange={e => setUserName(e.target.value)} required/>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="exampleInputPassword">Password</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="mdi mdi-lock-outline text-primary"></i>
                          </span>
                        </div>
                        <input type="password" className="form-control form-control-lg border-left-0" 
                        name="password" id="exampleInputPassword" placeholder="Password" 
                        onChange={e => setPassword(e.target.value)} required/>                        
                      </div>
                    </div>
                    <div className="my-3">
 
                       <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type="submit">Submit</button>
                     </div>

                    
                    <div className="my-2 d-flex justify-content-between align-items-center" style={{visibility: 'hidden'}}>
                      <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input type="checkbox" className="form-check-input" />
                          Keep me signed in
                        </label>
                      </div>
                      <a className="auth-link text-black">Forgot password?</a>
                    </div>
                    <div className="text-center mt-4 font-weight-light" style={{visibility: 'hidden'}}>
                      Don't have an account? <a href="register-2.html" className="text-primary">Create</a>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 login-half-bg d-flex flex-row">
                <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; 2021  All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
);

} 
Login.propTypes = {
  setToken: PropTypes.func.isRequired
}