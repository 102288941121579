import react, { useState, useEffect,useRef } from 'react'
import ReactApexChart from 'react-apexcharts';
import RegionCompo from './RegionCompo';
import MenuClickEvent from './MenuClickEvent'
import axios from 'axios';
import CagrBoxCompo from './CagrBoxCompo'
import TableCompo from './TableCompo'
import ChartCompo from './ChartCompo'
import {BrowserRouter as Router,Routes,Route,Navigate, Link} from 'react-router-dom';
import {charttype} from './ChartTypeJson'
 
const CountryChart = (props) => {
   
  let [cagr_data1, setcagr_data] = useState(false);
  let [listItems1, setlistItems] = useState([]); 
  let [datata, setdatata] = useState([]);
  let [loadingcheck, setloadingcheck] = useState(false);
  let [showComponent, setshowComponent] = useState(false); 

  const listItemRef= useRef({});
  listItemRef.current = listItems1;
   
    const callbar = async (response) => {
      

       
      let fieldArray=[];
      let fieldArrayValue=[];
      Object.entries(response).map((arr,index)=>{
              fieldArray[index]=arr[0];     
      })
     // console.log(response.data);
     Object.values(response).forEach((arri,index)=>{
      //fieldArrayValue[index].arri;
      fieldArrayValue[index]=arri;
     })

   
 

        
       let data_extract = response.coutrydata;
       let cagr_data=response.coutrydata[0];
         
        
        let listItems = data_extract.map((item, index, dataarray) => {

          let count_array = dataarray[index].tabledata.length;
          let optionsString;
          let seriesString;
          let currentChartType;
           
            charttype.map((charttype, chartindex,chartArray) => {

              Object.entries(chartArray[chartindex]).map(([key,subindex])=>{
                
                
                if(dataarray[index].chartTyape==key){
                   
                   chartArray[chartindex][key].series=dataarray[index].tabledata;
                   chartArray[chartindex][key].options.xaxis.categories=dataarray[index].year;
                    
                  optionsString =  chartArray[chartindex][key].options;
                  seriesString = chartArray[chartindex][key].series;
                  currentChartType=chartArray[chartindex][key].options.chart.type;
                  

                    
                }
 
                
            })
            

            }); 

            return (
              <div className="col-sm-6 grid-margin grid-margin-md-0 stretch-card mt-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="card-title">{dataarray[index].Heading.substring(dataarray[index].Heading.indexOf("Estimates & Forecast,") + 21)}</h4>
                      <h4 className="text-success font-weight-bold">USD Millions</h4>
                    </div>
  
                    <div id="support-tracker-legend" className="support-tracker-legend"></div>
  
                    <ReactApexChart options={optionsString} series={seriesString} type={currentChartType} height={350} />
                  </div>
                </div>
              </div>)

          
      }
        );
    
    
        
    
        /*setDataisLoaded(true);
        setBarchartsatate(listItems);
        setreport_title(data_extract[0].Heading);
        */
       setcagr_data(cagr_data);
     // console.log("test here");
       setlistItems(listItems);
      setloadingcheck(true);
       

       
      }
      let url="https://api.gmipulse.in/getcountrylistbyregionId/"+props.countryID;
      
    useEffect(() => {
 
      setlistItems('');
      fetch(url)
      .then(res => res.json())
      .then((json) => setdatata(callbar(json))
      );
      
    }, [props.countryID])

    
     
     
   

if (!loadingcheck || !listItems1) return <div>
      <h1>Data is Loading...  </h1> </div>;
return(

  <div>
        
  <div className="mixed-chart">
    <div className="container-fluid page-body-wrapper">
      <div className="main-panel">
        <div className="content-wrapper">
        
         
          <div className="row">
            {listItems1}
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="col-md-12  stretch-card">
              <div class="card">
                <div class="card-body">
                   
                 
                <Link to={"/"+props.reportID+"/"+props.name.replace(/\s+/g, '-').toLowerCase()+"/charts"} class="btn btn-primary btn-lg btn-block">Show All Charts</Link>
                    
                </div>
              </div>
            </div>
            
                

        
  
  <TableCompo countryID={props.countryID} />
  <div class="col-md-12  stretch-card">
              <div class="card">
                <div class="card-body">
                   
                 
                <Link to={"/"+props.reportID+"/"+props.name.replace(/\s+/g, '-').toLowerCase()+"/tables"} class="btn btn-primary btn-lg btn-block">Show All Tables</Link>
                    
                </div>
              </div>
            </div>
</div>

);

}


 



export default CountryChart;