var colors =["#F3B415", "#F27036", "#663F59", "#6A6E94", "#4E88B4", "#00A7C6", "#18D8D8", '#A9D794',
'#46AF78', '#A93F55', '#8C5E58', '#2176FF', '#33A1FD', '#7A918D', '#BAFF29']
export const charttype=[
                
   { "stacked": {

       series: "serisdata",
       options: {
       chart: {
           type: 'bar',
           height: 100,
           stacked: true,
           toolbar: {
            show: true,
            tools:{
                download: true,
                zoomin: false,
                          zoomout: false,
                          reset: false,
                          pan: false,
                          zoom: false
            },
            export: {
                png: {
                    filename: "undefined",
                  },
                  csv: {
                    filename: "undefined",
                  }
                
            }
          }
       },
       //colors: colors,
       plotOptions: {
           bar: {
           horizontal: false,
           },
       },
       dataLabels: {
            enabled: false
       },
       stroke: {
           width: 1,
           colors: ['#fff']
       },
       xaxis: {
           categories: "year",
           title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
           style: {
            fontSize: '12px',
            color:'#76838f',
            fontWeight: 100
          }},
           labels: {
           formatter: function (val) {
            
               return val
           }
           }
       },
       yaxis: {
           title: {
           text: undefined
           },
           labels: {
            formatter: function (val) {
              return val
            }
            }
       },
       tooltip: {
           y: {
           formatter: function (val) {
            return val
           }
           }
       },
       fill: {
           opacity: 1
       },
       legend: {
           position: 'top',
           horizontalAlign: 'left',
           offsetX: 40
       },
       theme: {
        palette: 'palette1' // upto palette10
      },
      title: {
        text: '',
        align:'center',
        style: {
         fontSize: '12px',
          fontWeight: 100,
          color: 'rgb(55, 61, 63)'
        }
      },
      subtitle: {
        text: '(Click on bar to see details)',
        align:'center',
      
      style: {
       fontSize: '12px',
        fontWeight: 100,
        color: 'rgb(55, 61, 63)'
      }},
       },


   }},
   {"linechart": {

       series: "serisdata",
       options: {
           chart: {
           height: 10,
           type: 'line',
           zoom: {
               enabled: false
           },
           toolbar: {
            show: true,
            tools:{
                download: true,
                zoomin: false,
                          zoomout: false,
                          reset: false,
                          pan: false,
                          zoom: false
            },
            export: {
                png: {
                    filename: "undefined",
                  },
                  csv: {
                    filename: "undefined",
                  }
            }
          }
           },
            
           dataLabels: {
           enabled: false
           }, yaxis: {
           labels: {
               formatter: function (val) {
                return val
               }
           }
           },
           stroke: {
           curve: 'straight'
           },

           grid: {
           row: {
               colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
               opacity: 0.5
           },
           },
           legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
           xaxis: {
           categories: "year",
           title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
           style: {
            fontSize: '12px',
            color:'#76838f',
            fontWeight: 100
          }}
           },
           theme: {
            palette: 'palette2' // upto palette10
          },
       
          title: {
            text: '',
            align:'center',
            style: {
             fontSize: '12px',
              fontWeight: 100,
              color: 'rgb(55, 61, 63)'
            }
          },
          subtitle: {
            text: '(Click on bar to see details)',
            align:'center',
          
          style: {
           fontSize: '12px',
            fontWeight: 100,
            color: 'rgb(55, 61, 63)'
          }},
    },
       }},
   {"barchart":{

           series: "serisdata",
           options: {
           chart: {
               type: 'bar',
               height: 350,
               toolbar: {
                show: true,
                tools:{
                    download: true,
                    zoomin: false,
                          zoomout: false,
                          reset: false,
                          pan: false,
                          zoom: false
                },
                export: {
                    png: {
                        filename: "undefined",
                      },
                      csv: {
                        filename: "undefined",
                      }
                }
              }
           },
           plotOptions: {
               bar: {
               horizontal: false,
               columnWidth: '55%',
               endingShape: 'rounded'
               },
           },
           dataLabels: {
               enabled: false
           },
           stroke: {
               show: true,
               width: 2,
               colors: ['transparent']
           },
           legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
           xaxis: {
               categories: "year",
               title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
           style: {
            fontSize: '12px',
            color:'#76838f',
            fontWeight: 100
          }}
           },

           yaxis: {
            labels: {
              formatter: function (val) {
                return val
              }
              }
           },
           
           fill: {
               opacity: 1
           },
           tooltip: {
               y: {
               formatter: function (val) {
                return val
               }
               }
           },
           theme: {
            palette: 'palette3' // upto palette10
          },
          title: {
            text: '',
            align:'center',
            style: {
             fontSize: '12px',
              fontWeight: 100,
              color: 'rgb(55, 61, 63)'
            }
          },
          subtitle: {
            text: '(Click on bar to see details)',
            align:'center',
          
          style: {
           fontSize: '12px',
            fontWeight: 100,
            color: 'rgb(55, 61, 63)'
          }},
           },

       }},{
   "radar":{ 

       series: "serisdata",
       options: {
       chart: {
           height: 350,
           type: 'radar',
           dropShadow: {
           enabled: true,
           blur: 1,
           left: 1,
           top: 1
           },
           toolbar: {
            show: true,
            tools:{
                download: true,
                zoomin: false,
                          zoomout: false,
                          reset: false,
                          pan: false,
                          zoom: false
            },
            export: {
                png: {
                    filename: "undefined",
                  }
            }
          }
       },
       stroke: {
           width: 2
       },
       fill: {
           opacity: 0.1
       },
       markers: {
           size: 0
       },
       legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
    },
       xaxis: {
           categories: "year",
           title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
           style: {
            fontSize: '12px',
            color:'#76838f',
            fontWeight: 100
          }}
       },
       theme: {
        palette: 'palette4' // upto palette10
      },
      title: {
        text: '',
        align:'center',
        style: {
         fontSize: '12px',
          fontWeight: 100,
          color: 'rgb(55, 61, 63)'
        }
      },
      subtitle: {
        text: '(Click on bar to see details)',
        align:'center',
      
      style: {
       fontSize: '12px',
        fontWeight: 100,
        color: 'rgb(55, 61, 63)'
      }},
       }
   
   
   }},{
    "heatmap":{ 
        
        series: "serisdata",
        options: {
            chart: {
              height: 450,
              type: 'heatmap',
              toolbar: {
                show: true,
                tools:{
                    download: true,
                    zoomin: false,
                          zoomout: false,
                          reset: false,
                          pan: false,
                          zoom: false
                },
                export: {
                    png: {
                        filename: "undefined",
                      },
                      csv: {
                        filename: "undefined",
                      }
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            //colors: colors,
            xaxis: {
              type: 'category',
              categories: "year",
              title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
           style: {
            fontSize: '12px',
            color:'#76838f',
            fontWeight: 100
          }}
            },
            yaxis: {
              /*tickAmount: 7*/
              labels: {
                formatter: function (val) {
                 return val
                }
                }
            },
            
            title: {
              text: ''
            },
            grid: {
              padding: {
                right: 20
              }
            },
            theme: {
              palette: 'palette10' // upto palette10
            },
            title: {
              text: '',
              align:'center',
              style: {
               fontSize: '12px',
                fontWeight: 100,
                color: 'rgb(55, 61, 63)'
              }
            },
            subtitle: {
              text: '(Click on bar to see details)',
              align:'center',
            
            style: {
             fontSize: '12px',
              fontWeight: 100,
              color: 'rgb(55, 61, 63)'
            }},
          },
    }},{
        "scatter":{ 
            
            series: "serisdata",
            options: {
                chart: {
                  height: 350,
                  type: 'scatter',
                  zoom: {
                    enabled: true,
                    type: 'xy'
                  },
                  toolbar: {
                    show: true,
                    tools:{
                        download: true,
                        zoomin: false,
                          zoomout: false,
                          reset: false,
                          pan: false,
                          zoom: false
                    },
                    export: {
                        png: {
                            filename: "undefined",
                          },
                          csv: {
                            filename: "undefined",
                          }
                    }
                  }
                },
                legend: {
                  position: 'top',
                  horizontalAlign: 'left',
                  offsetX: 40
              },
                xaxis: {
                  /*tickAmount: 10,*/
                  labels: {
                    formatter: function(val) {
                        return val
                    }
                  },
                  categories: "year",
                  title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
           style: {
            fontSize: '12px',
            color:'#76838f',
            fontWeight: 100
          }}
                },
                yaxis: {
                  /*tickAmount: 7*/
                  labels: {
                    formatter: function (val) {
                     return val
                    }
                    }
                },
                theme: {
                  palette: 'palette7' // upto palette10
                },
                title: {
                  text: '',
                  align:'center',
                  style: {
                   fontSize: '12px',
                    fontWeight: 100,
                    color: 'rgb(55, 61, 63)'
                  }
                },
                subtitle: {
                  text: '(Click on bar to see details)',
                  align:'center',
                
                style: {
                 fontSize: '12px',
                  fontWeight: 100,
                  color: 'rgb(55, 61, 63)'
                }},
              },
        }},{
            "pie":{ 
                
                series: "serisdata",
                options: {
                    chart: {
                      width: 380,
                      type: 'pie',
                    },
                    labels: "year",
                    responsive: [{
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 200
                        },
                        legend: {
                          position: 'top',
                          horizontalAlign: 'left',
                          offsetX: 40
                      },
                      }
                    }]
                  },
            }},
   {
   "area":{
                series: "serisdata",
                options: {
                  chart: {
                    height: '365px',
                    type: 'area',
                    toolbar: {
                      show: true,
                      tools:{
                          download: true,
                          zoomin: false,
                          zoomout: false,
                          reset: false,
                          pan: false,
                          zoom: false
                      },
                      export: {
                          png: {
                              filename: "undefined",
                            },
                            csv: {
                              filename: "undefined",
                            }
                      }
                    }
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    curve: 'smooth'
                  },
                  xaxis: {
                    type: 'number',
                    categories: "year",
                    title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',align:'right',
                    style: {
                      fontSize: '12px',
                      color:'#76838f',
                      fontWeight: 100
                    }}
                  },
                  yaxis: {
                    /*tickAmount: 7*/
                    labels: {
                      formatter: function (val) {
                       return val
                      }
                      }
                  },
                  legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                },
                  theme: {
                    palette: 'palette6' // upto palette10
                  },
                  title: {
                    text: '',
                    align:'center',
                    style: {
                     fontSize: '12px',
                      fontWeight: 100,
                      color: 'rgb(55, 61, 63)'
                    }
                  },
                  subtitle: {
                    text: '(Click on bar to see details)',
                    align:'center',
                  
                  style: {
                   fontSize: '12px',
                    fontWeight: 100,
                    color: 'rgb(55, 61, 63)'
                  }},
                },
              }
  },
  
{"linechart-HF2" : {
  series:"serisdata",
  options: {
  chart: {
    height: 350,
  type: 'line',
  toolbar: {
    show: true,
    tools:{
        download: true,
        zoomin: false,
        zoomout: false,
        reset: false,
        pan: false,
        zoom: false
    },
    export: {
        png: {
            filename: "undefined",
          },
          csv: {
            filename: "undefined",
          }
    }
  }
},
forecastDataPoints: {
  count: 14
},
stroke: {
  width: 5,
  curve: 'smooth'
},
legend: {
  position: 'top',
  horizontalAlign: 'left',
  offsetX: 40
},
xaxis: {
  type: 'datetime',
  categories: "year",
  tickAmount: 10,
  labels: {
    formatter: function(value, timestamp, opts) {
      return opts.dateFormatter(new Date(timestamp), 'dd MMM')
    }
  }
},
title: {
  text: '',
  align:'center',
  style: {
   fontSize: '19px',
    fontWeight: 100,
    color: 'rgb(55, 61, 63)'
  }
},
subtitle: {
  text: '(Click on bar to see details)',
  align:'center',

style: {
 fontSize: '14px',
  fontWeight: 100,
  color: 'rgb(55, 61, 63)'
}},
fill: {
  type: 'gradient',
  gradient: {
    shade: 'dark',
    shadeIntensity: 1,
    type: 'horizontal',
    opacityFrom: 1,
    opacityTo: 1,
    stops: [0, 100, 100, 100]
  },
}

}
}

},
{"linechart-HF" : {
  series:"serisdata",
  options: {
  chart: {
    height: 350,
  type: 'line',
  toolbar: {
    show: true,
    tools:{
        download: true,
        zoomin: false,
        zoomout: false,
        reset: false,
        pan: false,
        zoom: false
    },
    export: {
        png: {
            filename: "undefined",
          },
          csv: {
            filename: "undefined",
          }
    }
  }
},
forecastDataPoints: {
  count: 14
},
stroke: {
  width: 5,
  curve: 'smooth'
},
legend: {
  position: 'top',
  horizontalAlign: 'left',
  offsetX: 40
},
xaxis: {
  type: 'datetime',
  categories: "year",
  tickAmount: 10,
  labels: {
    formatter: function(value, timestamp, opts) {
      return opts.dateFormatter(new Date(timestamp), 'dd MMM')
    }
  }
},
title: {
  text: '',
  align:'center',
  style: {
   fontSize: '19px',
    fontWeight: 100,
    color: 'rgb(55, 61, 63)'
  }
},
subtitle: {
  text: '(Click on bar to see details)',
  align:'center',

style: {
 fontSize: '14px',
  fontWeight: 100,
  color: 'rgb(55, 61, 63)'
}},
fill: {
  type: 'gradient',
  gradient: {
    shade: 'dark',
    shadeIntensity: 1,
    type: 'horizontal',
    opacityFrom: 1,
    opacityTo: 1,
    stops: [0, 100, 100, 100]
  },
}

}
}

} 
]

 