import react, { useState, useEffect } from "react";
import AllChartCompo from "./AllChartCompo";
import AllTableCompo from "./AllTableCompo";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AllData from "./AllData";
import MonthAndWeekCompo from "./MonthAndWeekCompo";

const SideMenuBarDemo2 = (props) => {


   

  const [countrysate, setcountrysate] = useState("");
  const [disabled, setDisabled] = useState(
    props.name.replace(/\s+/g, "-").toLowerCase()
  );
  const [isVisualz, setisVisualz] = useState(false);
  const [uri, seturi] = useState("");
  let [DataisLoaded, setDataisLoaded] = useState(false);
  const [vtype, setvtype] = useState(props.ctype);

  const [resgionId, setresgionId] = useState("");
  const [countryiD, setcountryiD] = useState("6314f58f1dd6f730fbc9048c");
  const [commanflag, setcommanflag] = useState("");

  const [breadcrumbregion, setbreadcrumbregion] = useState("");
  const [breadcrumbvis, setbreadcrumbvis] = useState("");
  const [breadcrumbcountry, setbreadcrumbcountry] = useState("charts");

  const [iscountryexist, setiscountryexist] = useState(false);

  const [producttype, setproducttype] = useState("Polyethylene");
  const [countryname, setcountryname] = useState("China");

  const [month, setmonth] = useState(" ");
  const [week, setweek] = useState(" ");

  const [staticresgion, setstaticresgion] = useState(" ");

  const nav = useNavigate();
  //const [enableglobal, setenableglobal] = useState(true)
  let country_check_box;
  let chartDatavar = "";

  /*const [countryname, setcname] = useState(props.cname);
  if(countryname){
 
      console.log((props.cname);
      setcommanflag('countrydata');
      setbreadcrumbcountry(props.cname+" > ");
      setcountryiD(props.countryID);
      seturi('/'+props.reportID+'/'+props.name.replace(/\s+/g, '-').toLowerCase()+'/'+props.cname.replace(/\s+/g, '-').toLowerCase()+'/');
       
  
   
  } */
  function _onchangeResgionHandler(_resgionid, _reportid, _rname) {
    setDisabled(_rname);
    setcountrysate("");
    setiscountryexist(true);
    country_check_box = props.regiondata.map((rdata1) =>
      rdata1._id == _resgionid
        ? rdata1.mapping.length > 0
          ? rdata1.mapping.map((countrymenu) => (
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    name="countryRadios"
                    className="form-check-input"
                    onChange={() =>
                      _onCountryCheckBoxClick(
                        countrymenu._id,
                        _reportid,
                        countrymenu.cname,
                        _rname
                      )
                    }
                  />
                  {countrymenu.cname}
                  <i className="input-helper"></i>
                  <i className="input-helper"></i>
                </label>
              </div>
            ))
          : setiscountryexist(false)
        : ""
    );

    setDataisLoaded(false);
    setcountrysate(country_check_box);
    setresgionId(_resgionid);
    setcommanflag("regiondata");
    setbreadcrumbregion(_rname + " > ");
    setbreadcrumbcountry("");
    seturi("/" + _reportid + "/" + _rname + "/");
    nav("/" + _reportid + "/" + _rname + "/" + vtype);

    setbreadcrumbvis(vtype);
  }

  function _onCountryCheckBoxClick(cuntryID, rid, cname, rname) {
    setcommanflag("countrydata");
    setcountryname(cname);
    setbreadcrumbcountry(cname + " > ");
    setcountryiD(cuntryID);

    //seturi('/'+rid+'/'+rname+'/'+cname.replace(/\s+/g, '-').toLowerCase()+'/');
    //nav('/'+rid+'/'+rname+'/'+cname.replace(/\s+/g, '-').toLowerCase()+'/'+vtype);
  }

  function callApi(data) {
    if (data._RegionData.length) {
      setDataisLoaded(true);
    }
  }
  let datalen;

  function getDefaultCheckedData(defaultRegionID, rid, rname) {
    if (resgionId.length == 0) {
      //setcountryiD()
      setcommanflag("countrydata");
      setresgionId(defaultRegionID);
      setDataisLoaded(false);
      seturi("/" + rid + "/" + rname + "/");
      setbreadcrumbregion(rname + " > ");
    }
  }
  let resgiond;

  if (props.regiondata) {
    resgiond = props.regiondata.map((rdata) => (
      <div className="form-check">
        <label className="form-check-label">
          {disabled == rdata.name.replace(/\s+/g, "-").toLowerCase()
            ? getDefaultCheckedData(
                rdata._id,
                rdata.reportid,
                rdata.name.replace(/\s+/g, "-").toLowerCase()
              )
            : ""}

          <input
            type="radio"
            name="optionsRadios"
            id={rdata._id}
            checked={disabled === rdata.name.replace(/\s+/g, "-").toLowerCase()}
            className="form-check-input"
            onChange={(e) =>
              _onchangeResgionHandler(
                rdata._id,
                rdata.reportid,
                rdata.name.replace(/\s+/g, "-").toLowerCase()
              )
            }
          />

          {rdata.name.replace(/\b(\w)/g, (s) => s.toUpperCase())}
          <i className="input-helper"></i>
          <i className="input-helper"></i>
        </label>
      </div>
    ));
  }

  function _onvisualitazionChange(vitype) {
    setbreadcrumbvis(vitype);
    setvtype(vitype);
    nav(uri + vitype);
  }

  function _onproductChange(productname) {
    setproducttype(productname);
    setDataisLoaded(false);
    setmonth(" ");
    setweek(" ");
    setresgionId(resgionId);
    setcommanflag("countrydata");
    // setbreadcrumbregion(_rname+" > ");
    //setbreadcrumbcountry('');
    //seturi('/'+_reportid+'/'+_rname+'/');
    //nav('/'+_reportid+'/'+_rname+'/'+vtype);

    // setbreadcrumbvis(vtype);
  }

  const dropdownDataHandler = (monthweekdata) => {
   

    if (monthweekdata.month === "Select Month") {
      setmonth(" ");
    } else {
      setmonth(monthweekdata.month);
    }

    if (monthweekdata.week === "Select Week") {
      setweek(" ");
    } else {
      setweek(monthweekdata.week);
    }
  };

  function onsatticresgionHandler(staticresgion){
    setstaticresgion(staticresgion)

  }

  if (!DataisLoaded && resgionId.length == 0)
     
    return (
      <div
        className="container-fluid"
        style={{ background: "#f0f3f6", "text-align": "center" }}
      >
        <div
          className="row"
          style={{ display: "block", "text-align": "center" }}
        >
          <div>
            <img
              style={{ height: "4rem" }}
              src="/myCSS/images/loader.gif"
              alt="image"
              className="profile-pic"
            />{" "}
          </div>
        </div>
      </div>
    );
  return (
    <div>
      <div className="container-fluid page-body-wrapper">
        <div className="col-md-2 stretch-card">
          <div className="card" style={{"boxShadow": "none"}}>
            <div className="card-body">
               
              <p className="card-description">Select Product</p>
              <form>
                <div className="row">
                  <div className="col-md-12 cus-colmd-side">
                    <div className="form-group">
                      {
                        (country_check_box = props.regiondata.map((rdata1) =>
                          rdata1.products.length > 0
                            ? rdata1.products.map(
                                (prodcutarray, prodct_index) => (
                                  <div className="form-check">
                                    <label className="form-check-label">
                                      <input
                                        type="radio"
                                        name="countryRadios"
                                        value={prodcutarray}
                                        onChange={(e) =>
                                          _onproductChange(e.target.value)
                                        }
                                        checked={producttype === prodcutarray}
                                        className="form-check-input"
                                      />
                                      {prodcutarray}
                                      <i className="input-helper"></i>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                )
                              )
                            : setiscountryexist(false)
                        ))
                      }
                      {
                        (country_check_box = props.regiondata.map((rdata1) =>
                          rdata1.subproducts.length > 0
                            ? rdata1.subproducts.map(
                                (prodcutarray, prodct_index) => (
                                  <div className="form-check">
                                    <label className="form-check-label label-disabled">
                                      <input
                                        type="radio"
                                        name="countryRadios"
                                        value={prodcutarray}
                                        onChange={(e) =>
                                          _onproductChange(e.target.value)
                                        }
                                        className="form-check-input"
                                        disabled
                                      />
                                      {prodcutarray}
                                      <i className="input-helper"></i>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                )
                              )
                            : setiscountryexist(false)
                        ))
                      }
                    </div>
                  </div>
                </div>
              </form>
              <p className="card-description">Select Region</p>
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">{resgiond}
                    <div class="form-check"><label class="form-check-label label-disabled"  >
                      <input type="radio" onChange={(e) =>
              onsatticresgionHandler(e.value)

            } checked={staticresgion === "North America".replace(/\s+/g, "-").toLowerCase()} disabled value="North America" class="form-check-input" />North America<i class="input-helper"></i><i class="input-helper"></i></label>
                    </div>
                    <div class="form-check"><label class="form-check-label label-disabled" >
                      <input type="radio" checked={staticresgion === "Europe".replace(/\s+/g, "-").toLowerCase()} value="Europe" disabled class="form-check-input"  />Europe<i class="input-helper"></i><i class="input-helper"></i></label>
                    </div>
                    <div class="form-check"><label class="form-check-label label-disabled" >
                      <input type="radio" disabled  checked={staticresgion === "Latin America".replace(/\s+/g, "-").toLowerCase()} class="form-check-input" value="Latin America"  />Latin America<i class="input-helper"></i><i class="input-helper"></i></label>
                    </div>
                    <div class="form-check"><label class="form-check-label label-disabled">
                      <input type="radio" disabled checked={staticresgion === "MEA".replace(/\s+/g, "-").toLowerCase()} class="form-check-input" value="MEA"  />MEA<i class="input-helper"></i><i class="input-helper"></i></label>
                    </div>

                    </div>
                  </div>
                </div>
              </form>

             { /*<p className="card-description">Visualization</p>
              <form>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            name="optionsRadios"
                            value="alldata"
                            checked={vtype === "alldata"}
                            onChange={(e) =>
                              _onvisualitazionChange(e.target.value)
                            }
                            class="form-check-input"
                          />
                          All Data
                          <i class="input-helper"></i>
                          <i class="input-helper"></i>
                          <i class="input-helper"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                          </form>*/}
              {/*console.log(iscountryexist)*/}
              {iscountryexist ? (
                <p className="card-description">Select Country</p>
              ) : (
                ""
              )}
              <p className="card-description">Select Country</p>
              <form>
                {console.log(props.regiondata)}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      {
                         
                        (country_check_box = props.regiondata.map((rdata1) =>
                        
                          rdata1.mapping.length > 0
                            ? rdata1.mapping.map(
                                (countrymenu, countryindex) => (
                                  <div className="form-check">
                                    <label className="form-check-label" >
                                      <input
                                        type="radio"
                                        name="countryRadios"
                                        value={countrymenu.cname}
                                        checked={
                                          countryname === countrymenu.cname
                                        }
                                         
                                        onChange={(e) =>
                                          _onCountryCheckBoxClick(
                                            countrymenu._id,
                                            countrymenu.reportid,
                                            countrymenu.cname,
                                            rdata1.name
                                              .replace(/\s+/g, "-")
                                              .toLowerCase()
                                          )
                                        }
                                        className="form-check-input"
                                      />
                                      {countrymenu.cname}
                                      <i className="input-helper"></i>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                )
                              )
                            : setiscountryexist(false)
                        ))
                      }
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-md-10" style={{"background": "#f0f3f6"}}>
          <div className="mixed-chart">
            <div className="container-fluid page-body-wrapper">
              <div className="main-panel" style={{padding: "20px"}}>

        

        {vtype == "charts" ? (
          commanflag == "regiondata" ? (
            resgionId.length > 0 ? (
              <AllChartCompo
                prodtype={producttype}
                breadcrumb={
                  breadcrumbregion.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbcountry.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbvis.replace(/\b(\w)/g, (s) => s.toUpperCase())
                }
                resgionID={resgionId}
              />
            ) : (
              console.log("wrong")
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {vtype == "tables" ? (
          commanflag == "regiondata" ? (
            resgionId.length > 0 ? (
              <AllTableCompo
                prodtype={producttype}
                breadcrumb={
                  breadcrumbregion.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbcountry.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbvis.replace(/\b(\w)/g, (s) => s.toUpperCase())
                }
                resgionID={resgionId}
              />
            ) : (
              console.log("wrong")
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {vtype == "charts" ? (
          commanflag == "countrydata" ? (
            countryiD.length > 0 ? (
              <AllChartCompo
                prodtype={producttype}
                breadcrumb={
                  breadcrumbregion.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbcountry.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbvis.replace(/\b(\w)/g, (s) => s.toUpperCase())
                }
                countryID={countryiD}
              />
            ) : (
              console.log("wrong")
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {vtype == "tables" ? (
          commanflag == "countrydata" ? (
            countryiD.length > 0 ? (
              <AllTableCompo
                prodtype={producttype}
                breadcrumb={
                  breadcrumbregion.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbcountry.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbvis.replace(/\b(\w)/g, (s) => s.toUpperCase())
                }
                countryID={countryiD}
              />
            ) : (
              console.log("wrong")
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {vtype == "alldata" ? (
          commanflag == "countrydata" ? (
            countryiD.length > 0 ? (
              <AllData
                month={month}
                week={week}
                prodtype={producttype}
                breadcrumb={
                  breadcrumbregion.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbcountry.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbvis.replace(/\b(\w)/g, (s) => s.toUpperCase())
                }
                countryID={countryiD}
              />
            ) : (
              console.log("wrong")
            )
          ) : (
            console.log("wrong")
          )
        ) : (
          ""
        )}
        {vtype == "alldata" ? (
          commanflag == "regiondata" ? (
            resgionId.length > 0 ? (
              <AllData
                month={month}
                week={week}
                prodtype={producttype}
                breadcrumb={
                  breadcrumbregion.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbcountry.replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                  breadcrumbvis.replace(/\b(\w)/g, (s) => s.toUpperCase())
                }
                resgionID={resgionId}
              />
            ) : (
              console.log("wrong")
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}
        </div>
        </div>
        </div>
        </div>
        </div>
      </div>
  );
};

export default SideMenuBarDemo2;
