import react, {useState, useEffect} from 'react'
import ChartCompo from './ChartCompo'
import AllChartCompo from './AllChartCompo'
import AllTableCompo from './AllTableCompo'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,useNavigate
} from "react-router-dom";

import { getStateNameByCountryId, getCountryDataByID } from "../api-services/AllAPI";

const SideMenuBar = (props) => {

    
  const [countrysate, setcountrysate]=useState('');
  const [disabled, setDisabled] = useState(props.name.replace(/\s+/g, '-').toLowerCase());
  const [isVisualz, setisVisualz] = useState(false);
  const [uri, seturi] = useState('');
  let [DataisLoaded, setDataisLoaded] = useState(false); 
  const [vtype, setvtype] = useState(props.ctype); 
  const [resgionId, setresgionId] =useState('');
  const [countryiD, setcountryiD] =useState('');
  const [commanflag, setcommanflag] =useState('');

  const [breadcrumbregion, setbreadcrumbregion] =useState('');
  const [breadcrumbvis, setbreadcrumbvis] =useState('');
  const [breadcrumbcountry, setbreadcrumbcountry] =useState('charts');

  const [iscountryexist, setiscountryexist] =useState(false);

  const [stateRadio, setstateRadio] =useState(false);
  const [stateId, setstateId] =useState('');
  
  const nav = useNavigate();
  //const [enableglobal, setenableglobal] = useState(true)
  let country_check_box;
  let chartDatavar="";

  /*const [countryname, setcname] = useState(props.cname);
  if(countryname){
 
      console.log((props.cname);
      setcommanflag('countrydata');
      setbreadcrumbcountry(props.cname+" > ");
      setcountryiD(props.countryID);
      seturi('/'+props.reportID+'/'+props.name.replace(/\s+/g, '-').toLowerCase()+'/'+props.cname.replace(/\s+/g, '-').toLowerCase()+'/');
       
  
   
  } */
  function _onchangeResgionHandler(checkedevent,_resgionid, _reportid, _rname) {
    setstateRadio(false);
    setcountryiD('');
   setDisabled(_rname);
   setcountrysate('');
   setiscountryexist(true)
   country_check_box = props.regiondata.map((rdata1) =>
      rdata1._id==_resgionid?
        rdata1.mapping.length > 0 ?  
              rdata1.mapping.map(countrymenu =>
                  <div className="form-check">
                  <label className={countrymenu.active=='no'?"form-check-label label-disabled": "form-check-label "}>
                    <input type="radio" disabled={countrymenu.active=='no'} name="countryRadios" className="form-check-input"  onChange={()=>_onCountryCheckBoxClick(countrymenu._id,_reportid,countrymenu.cname,_rname)} />
                    {countrymenu.cname}
              <i className="input-helper"></i><i className="input-helper"></i>
                  </label>
                </div>
                    )
          : setiscountryexist(false)
      :''
   )
 
   
   setDataisLoaded(false);
   setcountrysate(country_check_box);
   setresgionId(_resgionid);
   setcommanflag('regiondata');
   if(_rname==="mea"){

    
    setbreadcrumbregion("MEA"+" > ");
   }else{
   
    setbreadcrumbregion(_rname.replace(/\b(\w)/g, s => s.toUpperCase())+" > ");
   }
  
   setbreadcrumbcountry('');
    seturi('/'+_reportid+'/'+_rname+'/');
    nav('/'+_reportid+'/'+_rname+'/'+vtype);
   
    setbreadcrumbvis(vtype);
  }

  function _onCountryCheckBoxClick(cuntryID,rid,cname,rname){

    setcommanflag('countrydata');
    setbreadcrumbcountry(cname+" > ");
    setcountryiD(cuntryID);
    seturi('/'+rid+'/'+rname+'/'+cname.replace(/\s+/g, '-').toLowerCase()+'/');
    nav('/'+rid+'/'+rname+'/'+cname.replace(/\s+/g, '-').toLowerCase()+'/'+vtype);

  }
  
  function _onStateCheckBoxClick(stateid){
   
    setcommanflag('statedata');
    setstateId(stateid)

  }

  useEffect(  () => {

    async function callapi() {
      
            
            let  response = await getStateNameByCountryId(countryiD);   
            
              if(response !== undefined){
 
                let  state_check_box = response.map((statedataarray) =>
                 
                              <div className="form-check">
                              <label className="form-check-label">
                                <input type="radio" name="stateRadios" className="form-check-input"  onChange={()=>_onStateCheckBoxClick(statedataarray._id)} />
                                {statedataarray.statename}
                                <i className="input-helper"></i><i className="input-helper"></i>
                              </label>
                            </div>
                               
                    
              )
              setstateRadio(state_check_box);
              }else{
                   
                setstateRadio(false);

              }

            
        
      }
      callapi();
     
  },[countryiD])

   
  let  datalen;

  function getDefaultCheckedData(defaultRegionID,rid, rname){

     
   
    if(resgionId.length == 0 ){

      setcommanflag('regiondata');
     setresgionId(defaultRegionID);
     setDataisLoaded(false);
     seturi('/'+rid+'/'+rname+'/');
     if(rname==="mea"){

    
      setbreadcrumbregion("MEA"+" > ");
     }else{
  
      setbreadcrumbregion(rname.replace(/\b(\w)/g, s => s.toUpperCase())+" > ");
     }
      


    }

  
      
     
  }
  let   resgiond;
 
  
  if (props.regiondata) {

     
    
     resgiond= props.regiondata.map((rdata) =>

      

     <div className="form-check">
        <label className="form-check-label">
        {
          disabled==rdata.name.replace(/\s+/g, '-').toLowerCase()? getDefaultCheckedData(rdata._id,rdata.reportid, rdata.name.replace(/\s+/g, '-').toLowerCase()):''
        }
         <input type="radio" name="optionsRadios" id={rdata._id}  checked={disabled ===  rdata.name.replace(/\s+/g, '-').toLowerCase()} className="form-check-input"  onChange={(e) => _onchangeResgionHandler(e.target.checked,rdata._id, rdata.reportid, rdata.name.replace(/\s+/g, '-').toLowerCase())} /> 
         
          {rdata.name.replace(/\b(\w)/g, s => s.toUpperCase())}
          <i className="input-helper"></i><i className="input-helper"></i></label>
      
      </div>

    )
  
  }

  function _onvisualitazionChange(vitype){
    
    setbreadcrumbvis(vitype);
    setvtype(vitype);
    nav(uri+vitype);
    

  }

  if (!DataisLoaded && resgionId.length ==0) return( 
  <div className="container-fluid" style={{'background': "#f0f3f6", 'text-align': "center" }}>
            <div className="row" style={{'display': "block", 'text-align': "center" }}>
            <div >
   <img style={{'height': '4rem' }} src="/myCSS/images/loader.gif" alt="image" className="profile-pic" /> </div>
            </div>
      </div>)
  return (
    <div>
      <div className="container-fluid page-body-wrapper">
        <div className="col-md-2 stretch-card">
          <div className="card">
            <div className="card-body">
              <p className="card-description">Select Region</p>
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      {resgiond}
                    </div>
                  </div>

                </div>
              </form>
              <p className="card-description">Visualization</p>
              <form>
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="form-group">
                                            <div class="form-check">
                                              <label class="form-check-label">
                                                <input type="radio" name="optionsRadios" class="form-check-input" checked={vtype==="charts"} value='charts' onChange={(e) => _onvisualitazionChange(e.target.value)} />
                                                
                                                Chart
                                              <i class="input-helper"></i><i class="input-helper"></i><i class="input-helper"></i></label>
                                            </div>
                                            <div class="form-check">
                                              <label class="form-check-label">
                                                <input type="radio" name="optionsRadios" value='tables'  checked={vtype==="tables"} onChange={(e) => _onvisualitazionChange(e.target.value)}class="form-check-input"/>
                                                Table
                                              <i class="input-helper"></i><i class="input-helper"></i><i class="input-helper"></i></label>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </form>
               {/*console.log(iscountryexist)*/}
               {  iscountryexist ? <p className="card-description">Select Country</p> :'' }
              
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      
                   {countrysate}


                    </div>
                  </div>

                </div>
              </form>
              {  stateRadio ? <p className="card-description">Select State</p> :''   }
              
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      
                   {stateRadio}


                    </div>
                  </div>

                </div>
              </form>

            </div>

          </div>
        </div>
        {
vtype=="charts"? commanflag=="regiondata"? resgionId.length > 0 ? <AllChartCompo breadcrumb={breadcrumbregion+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} resgionID={resgionId} />:console.log('wrong'):'':''
        }
        {
 vtype=="tables"?  commanflag=="regiondata"? resgionId.length > 0 ? <AllTableCompo  breadcrumb={breadcrumbregion+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} resgionID={resgionId} />:console.log('wrong'):'':''
        }
         {
vtype=="charts"?  commanflag=="countrydata"? countryiD.length > 0 ? <AllChartCompo  breadcrumb={breadcrumbregion+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} countryID={countryiD} />:console.log('wrong'):'':''
        }
        {
vtype=="tables"?  commanflag=="countrydata"?  countryiD.length > 0 ? <AllTableCompo  breadcrumb={breadcrumbregion+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} countryID={countryiD} />:console.log('wrong'):'':''
        }
        {
vtype=="charts"?  commanflag=="statedata"? stateId.length > 0 ? <AllChartCompo  breadcrumb={breadcrumbregion+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} stateID={stateId} />:console.log('wrong'):'':''
        }
        {
vtype=="tables"?  commanflag=="statedata"?  stateId.length > 0 ? <AllTableCompo  breadcrumb={breadcrumbregion+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} stateID={stateId} />:console.log('wrong'):'':''
        }
       
      </div>

    </div>
  );
}

export default SideMenuBar;