import ReactApexChart from 'react-apexcharts';
import react, { useState, useEffect } from 'react'
import Nav2 from './MyUICompo/Nav2'
import {BrowserRouter as Router,Routes,Route,Navigate,useLocation,useNavigate,useParams, Link} from 'react-router-dom';



const TableCompo = (props) =>{

    let [cagr_data1, setcagr_data] = useState(false);
  let [listItems1, setlistItems] = useState([]); 
  let [datata, setdatata] = useState([]);
  let [loadingcheck, setloadingcheck] = useState(false);
  let [showComponent, setshowComponent] = useState(false); 
 
   function callbar(data) {


        //console.log(data.GLOBAL);

        let data_extract='';
       if(props.countryID){
         data_extract = data.coutrydata;
         
       }else{
         data_extract = data._RegionData;
       }
       
        

        // console.log(data_extract);



        // return ( <ReactApexChart options={barchart1.options} series={barchart1.series} type="bar" height={350} />);


        let listItems = data_extract.map((item, index, dataarray) => {

           
             
            return (
                <div className="col-sm-12 grid-margin grid-margin-md-0 stretch-card mt-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="card-title">{dataarray[index].Heading.substring(dataarray[index].Heading.indexOf("Estimates & Forecast,") + 21)}</h4>
                                <h4 className="text-success font-weight-bold">USD Millions</h4>
                            </div>

                            <div id="support-tracker-legend" className="support-tracker-legend"></div>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Component</th>
                                        {dataarray[index].year.map(test => <td>{test} </td>)}
                                        <th>CAGR</th>
                                    </tr>
                                </thead>
                                <tbody>
                               
                                                                           
                                          
                                            
                                 
                                     {dataarray[index].tabledata.map((tblmap,idx,tblarray) =>{   
                                            
                                            return(
                                        <tr> 

                                        
                                        <td>{tblarray[idx].name}</td>
                                        {tblarray[idx].data.map(maindata => <td>{maindata.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>)}
                                        <td>{tblarray[idx].cagr}</td>
                                        </tr>)
                                        }
                                        )}
                                        
                                 
                                     
                                </tbody>
                            </table>

                           
                        </div>
                    </div>
                </div>)



        }

        );


         

         
          setlistItems(listItems);
          setloadingcheck(true);
          

         
    }
        
     
    const search = useLocation().search;
    const query_string_resgionID = new URLSearchParams(search).get('resgionId');
    let url;
   if(props.countryID){

      url ="https://api.gmipulse.in/getcountrylistbyregionId/"+props.countryID;
   // url="https://api.gmipulse.in/getsinglecountrybyID/"+props.countryID;

   }else
   if(props.resgionID){

       url="https://api.gmipulse.in/RegionsLandingData/"+props.resgionID;
       
   }else if(query_string_resgionID){

      url="https://api.gmipulse.in/RegionsLandingData/"+query_string_resgionID;
       
   }else{
      url="https://api.gmipulse.in/RegionsLandingData/62cf10e39d34e7bab96f3e11";
   }
    
     
      
    useEffect(() => {
 

      fetch(url)
      .then(res => res.json())
      .then((json) => callbar(json)
      );
      
    }, [])


       

        if (!loadingcheck || !listItems1) return <div>
            <h1> Data is Loading... </h1> </div>;
        return (
            <div>
                 
                <div className="mixed-chart">
                    <div className="container-fluid page-body-wrapper">
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row mt-4">
                                    {listItems1}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
 
}



export default TableCompo;