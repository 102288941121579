import react, { useState, useEffect } from 'react'
import axios from 'axios';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation
  } from "react-router-dom";
import StaticMenu from './StaticMenu'
import { NavLink } from 'react-router-dom';
  
const RegionCompo = (props) => {
    const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const urlsplit=window.location.pathname;
  const spliturl=urlsplit.split("/");
   
if(props.rid){
  

       function _onclickResgionHandler(_resgion_id, report_id,_resgion_name){
        //console.log(getReagionId);
        let idsprop={
            "resgion_id":_resgion_id,
            "report_id":report_id
        }
        setregionId(_resgion_id);
        setReportId(report_id);
        setcheckResgion(true);
        setresgionName(_resgion_name);
       
         
        props.onregionchange(idsprop);
      }
   
      function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        // Directly return the joined string
        return splitStr.join(' '); 
     }

    function _getAssignRegion(JsonData){
   
        

        let datatest=JsonData.map((test) => 
             
        <li  key={test._id}  className="nav-item "  onClick={() => _onclickResgionHandler(test._id,test.reportid,test.name.replace(/\s+/g, '-').toLowerCase())} >
            <NavLink  className="nav-link" to={"/"+ test.reportid +"/"+ test.name.replace(/\s+/g, '-').toLowerCase()+"?resgionId="+test._id}><i className="mdi mdi-file-document-box menu-icon"></i><span className="menu-title">{test.name.toUpperCase()}</span></NavLink>

            
            {
                test.mapping.length > 0 ? <div className="submenu"><ul> 
                    { test.mapping.map(countrymenu =>

                          <li className="nav-item">
                           <Link className="nav-link" to={"/"+ test.reportid +"/"+ test.name.replace(/\s+/g, '-').toLowerCase()+"/"+countrymenu.cname.replace(/\s+/g, '-').toLowerCase()}>{countrymenu.cname} </Link>
                           
                          </li>
                          )}
                      </ul>
                  </div>
                : <p></p>
            }
           
           
        </li>
        
      
        )
        //console.log(datatest);
       return datatest;
      }

  let [datatest, setResponseData] = react.useState('');
  let [reportid, setReportId] = react.useState('');
  let [regionId, setregionId] = react.useState('');
  let [checkResgion, setcheckResgion] = react.useState(false);
  let [resgionName, setresgionName] = react.useState();
   
    const fetchData = react.useCallback(() => {
        axios({
            "method": "POST",
            "url": "https://api.gmipulse.in/check-gmi-pulse-token",
            "headers": {
              "Content-Type": "application/json",
              "Accept": "application/json",
              'apitoken': props.rid
            }, "params": {
              "language_code": "en"
            }
            
          })
        .then((response) => {
            setResponseData(_getAssignRegion(response.data))
            setReportId(response.data[0].reportid);
            console.log(response.data[0].reportid);
        })
        .catch((error) => {
          console.log(error)
        })
      }, [])
      react.useEffect(() => {
        fetchData()
      }, [fetchData])
     
     
     
    return (

        <>
            <nav className="bottom-navbar" style={{"z-index": "9999999"}}>
                <div className="container p-2">
                <div className="col-sm-6 mb-4 mb-xl-0">

            <div className="d-lg-flex align-items-center">
              <div>
                <h3 className="text-dark font-weight-bold mb-2">Power Transmission Component Market</h3>
                <h6 className="font-weight-normal mb-2">Energy, Mining, Oil & Gas</h6>
              </div>

            </div>
          </div>

                    <ul className="nav page-navigation">
                    {datatest}
  
                    </ul>

                </div>
            </nav>
            <StaticMenu resgionId={regionId} reportId={reportid} isResgionset={checkResgion} resgionName={resgionName} urlpath={splitLocation} actualurl={pathname}/>
           
        </>
    );

}
}

export default RegionCompo;