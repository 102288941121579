import React from "react"

export  function  getchart(charttype){


    switch(charttype) {
        case 'stacked':
           let stacked= {

                series: "serisdata",
                options: {
                chart: {
                    type: 'bar',
                    height: 100,
                    stacked: true,
                    toolbar: {
                    show: true,
                    tools:{
                        download: true,
                        zoomin: false,
                                zoomout: false,
                                reset: false,
                                pan: false,
                                zoom: false
                    },
                    export: {
                        png: {
                            filename: "undefined",
                        },
                        csv: {
                            filename: "undefined",
                        }
                    }
                }
                },
                //colors: colors,
                plotOptions: {
                    bar: {
                    horizontal: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: "year",
                    title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                    style: {
                    fontSize: '12px',
                    color:'#76838f',
                    fontWeight: 100
                }},
                    labels: {
                    formatter: function (val) {
                        return val
                    }
                    }
                },
                yaxis: {
                    title: {
                    text: undefined
                    },
                },
                tooltip: {
                    y: {
                    formatter: function (val) {
                        return val
                    }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                },
                theme: {
                palette: 'palette1' // upto palette10
            },
            title: {
                text: '',
                align:'center',
                style: {
                fontSize: '12px',
                fontWeight: 100,
                color: 'rgb(55, 61, 63)'
                }
            },
            subtitle: {
                text: '(Click on bar to see details)',
                align:'center',
            
            style: {
                fontSize: '12px',
                fontWeight: 100,
                color: 'rgb(55, 61, 63)'
            }},
                },
        

        }
          return stacked;
          case 'stackdup':
           let stackdup= {

                series: "serisdata",
                options: {
                chart: {
                    type: 'bar',
                    height: 100,
                    stacked: true,
                    toolbar: {
                    show: true,
                    tools:{
                        download: true,
                        zoomin: false,
                                zoomout: false,
                                reset: false,
                                pan: false,
                                zoom: false
                    },
                    export: {
                        png: {
                            filename: "undefined",
                        },
                        csv: {
                            filename: "undefined",
                        }
                    }
                }
                },
                //colors: colors,
                plotOptions: {
                    bar: {
                    horizontal: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: "year",
                    title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                    style: {
                    fontSize: '12px',
                    color:'#76838f',
                    fontWeight: 100
                }},
                    labels: {
                    formatter: function (val) {
                        return val
                    }
                    }
                },
                yaxis: {
                    title: {
                    text: undefined
                    },
                },
                tooltip: {
                    y: {
                    formatter: function (val) {
                        return val
                    }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                },
                theme: {
                palette: 'palette1' // upto palette10
            },
            title: {
                text: '',
                align:'center',
                style: {
                fontSize: '12px',
                fontWeight: 100,
                color: 'rgb(55, 61, 63)'
                }
            },
            subtitle: {
                text: '(Click on bar to see details)',
                align:'center',
            
            style: {
                fontSize: '12px',
                fontWeight: 100,
                color: 'rgb(55, 61, 63)'
            }},
                },
        

        }
          return stackdup;
        case 'stackdupdup':
           let stackdupdup= {

                series: "serisdata",
                options: {
                chart: {
                    type: 'bar',
                    height: 100,
                    stacked: true,
                    toolbar: {
                    show: true,
                    tools:{
                        download: true,
                        zoomin: false,
                                zoomout: false,
                                reset: false,
                                pan: false,
                                zoom: false
                    },
                    export: {
                        png: {
                            filename: "undefined",
                        },
                        csv: {
                            filename: "undefined",
                        }
                    }
                }
                },
                //colors: colors,
                plotOptions: {
                    bar: {
                    horizontal: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: "year",
                    title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                    style: {
                    fontSize: '12px',
                    color:'#76838f',
                    fontWeight: 100
                }},
                    labels: {
                    formatter: function (val) {
                        return val
                    }
                    }
                },
                yaxis: {
                    title: {
                    text: undefined
                    },
                },
                tooltip: {
                    y: {
                    formatter: function (val) {
                        return val
                    }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                },
                theme: {
                palette: 'palette1' // upto palette10
            },
            title: {
                text: '',
                align:'center',
                style: {
                fontSize: '12px',
                fontWeight: 100,
                color: 'rgb(55, 61, 63)'
                }
            },
            subtitle: {
                text: '(Click on bar to see details)',
                align:'center',
            
            style: {
                fontSize: '12px',
                fontWeight: 100,
                color: 'rgb(55, 61, 63)'
            }},
                },
        

            }
          return stackdupdup;
        case 'stackdupduputtar':
            let stackdupduputtar= {
 
                 series: "serisdata",
                 options: {
                 chart: {
                     type: 'bar',
                     height: 100,
                     stacked: true,
                     toolbar: {
                     show: true,
                     tools:{
                         download: true,
                         zoomin: false,
                                 zoomout: false,
                                 reset: false,
                                 pan: false,
                                 zoom: false
                     },
                     export: {
                         png: {
                             filename: "undefined",
                         },
                         csv: {
                             filename: "undefined",
                         }
                     }
                 }
                 },
                 //colors: colors,
                 plotOptions: {
                     bar: {
                     horizontal: false,
                     },
                 },
                 dataLabels: {
                     enabled: false
                 },
                 stroke: {
                     width: 1,
                     colors: ['#fff']
                 },
                 xaxis: {
                     categories: "year",
                     title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                     style: {
                     fontSize: '12px',
                     color:'#76838f',
                     fontWeight: 100
                 }},
                     labels: {
                     formatter: function (val) {
                         return val
                     }
                     }
                 },
                 yaxis: {
                     title: {
                     text: undefined
                     },
                 },
                 tooltip: {
                     y: {
                     formatter: function (val) {
                         return val
                     }
                     }
                 },
                 fill: {
                     opacity: 1
                 },
                 legend: {
                     position: 'top',
                     horizontalAlign: 'left',
                     offsetX: 40
                 },
                 theme: {
                 palette: 'palette1' // upto palette10
             },
             title: {
                 text: '',
                 align:'center',
                 style: {
                 fontSize: '12px',
                 fontWeight: 100,
                 color: 'rgb(55, 61, 63)'
                 }
             },
             subtitle: {
                 text: '(Click on bar to see details)',
                 align:'center',
             
             style: {
                 fontSize: '12px',
                 fontWeight: 100,
                 color: 'rgb(55, 61, 63)'
             }},
                 },
         
 
             }
           return stackdupduputtar;
        case 'linechart':
            let linechart= {

                            series: "serisdata",
                            options: {
                                chart: {
                                height: 10,
                                type: 'line',
                                zoom: {
                                    enabled: false
                                },
                                toolbar: {
                                show: true,
                                tools:{
                                    download: true,
                                    zoomin: false,
                                            zoomout: false,
                                            reset: false,
                                            pan: false,
                                            zoom: false
                                },
                                export: {
                                    png: {
                                        filename: "undefined",
                                    },
                                    csv: {
                                        filename: "undefined",
                                    }
                                }
                            }
                                },
                                
                                dataLabels: {
                                enabled: false
                                }, yaxis: {
                                labels: {
                                    formatter: function (val) {
                                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                }
                                },
                                stroke: {
                                curve: 'straight'
                                },
                    
                                grid: {
                                row: {
                                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                    opacity: 0.5
                                },
                                },
                                legend: {
                                position: 'top',
                                horizontalAlign: 'left',
                                offsetX: 40
                            },
                                xaxis: {
                                categories: "year",
                                title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                                style: {
                                fontSize: '12px',
                                color:'#76838f',
                                fontWeight: 100
                            }}
                                },
                                theme: {
                                palette: 'palette2' // upto palette10
                            },
                            
                            title: {
                                text: '',
                                align:'center',
                                style: {
                                fontSize: '12px',
                                fontWeight: 100,
                                color: 'rgb(55, 61, 63)'
                                }
                            },
                            subtitle: {
                                text: '(Click on bar to see details)',
                                align:'center',
                            
                            style: {
                                fontSize: '12px',
                                fontWeight: 100,
                                color: 'rgb(55, 61, 63)'
                            }},
                        },
                }
        return linechart;
        case 'linechartdup':
         let linechartdup={

                    series: "serisdata",
                    options: {
                        chart: {
                        height: 10,
                        type: 'line',
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                        show: true,
                        tools:{
                            download: true,
                            zoomin: false,
                                    zoomout: false,
                                    reset: false,
                                    pan: false,
                                    zoom: false
                        },
                        export: {
                            png: {
                                filename: "undefined",
                            },
                            csv: {
                                filename: "undefined",
                            }
                        }
                    }
                        },
                        
                        dataLabels: {
                        enabled: false
                        }, yaxis: {
                        labels: {
                            formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                        }
                        },
                        stroke: {
                        curve: 'straight'
                        },
            
                        grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                        },
                        legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                        xaxis: {
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                        style: {
                        fontSize: '12px',
                        color:'#76838f',
                        fontWeight: 100
                    }}
                        },
                        theme: {
                        palette: 'palette2' // upto palette10
                    },
                    
                    title: {
                        text: '',
                        align:'center',
                        style: {
                        fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                        }
                    },
                    subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                    
                    style: {
                        fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                    }},
                },
            }
        return linechartdup;
        case 'linechartdupdup':
         let linechartdupdup={

                    series: "serisdata",
                    options: {
                        chart: {
                        height: 10,
                        type: 'line',
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                        show: true,
                        tools:{
                            download: true,
                            zoomin: false,
                                    zoomout: false,
                                    reset: false,
                                    pan: false,
                                    zoom: false
                        },
                        export: {
                            png: {
                                filename: "undefined",
                            },
                            csv: {
                                filename: "undefined",
                            }
                        }
                    }
                        },
                        
                        dataLabels: {
                        enabled: false
                        }, yaxis: {
                        labels: {
                            formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                        }
                        },
                        stroke: {
                        curve: 'straight'
                        },
            
                        grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                        },
                        legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                        xaxis: {
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                        style: {
                        fontSize: '12px',
                        color:'#76838f',
                        fontWeight: 100
                    }}
                        },
                        theme: {
                        palette: 'palette2' // upto palette10
                    },
                    
                    title: {
                        text: '',
                        align:'center',
                        style: {
                        fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                        }
                    },
                    subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                    
                    style: {
                        fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                    }},
                },
            }
        return linechartdupdup;
        case 'linechartdupduputtar':
         let linechartdupduputtar={

                    series: "serisdata",
                    options: {
                        chart: {
                        height: 10,
                        type: 'line',
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                        show: true,
                        tools:{
                            download: true,
                            zoomin: false,
                                    zoomout: false,
                                    reset: false,
                                    pan: false,
                                    zoom: false
                        },
                        export: {
                            png: {
                                filename: "undefined",
                            },
                            csv: {
                                filename: "undefined",
                            }
                        }
                    }
                        },
                        
                        dataLabels: {
                        enabled: false
                        }, yaxis: {
                        labels: {
                            formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                        }
                        },
                        stroke: {
                        curve: 'straight'
                        },
            
                        grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                        },
                        legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                        xaxis: {
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                        style: {
                        fontSize: '12px',
                        color:'#76838f',
                        fontWeight: 100
                    }}
                        },
                        theme: {
                        palette: 'palette2' // upto palette10
                    },
                    
                    title: {
                        text: '',
                        align:'center',
                        style: {
                        fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                        }
                    },
                    subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                    
                    style: {
                        fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                    }},
                },
            }
        return linechartdupduputtar;
        case 'barchart':
         let barchart ={

                series: "serisdata",
                options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                     show: true,
                     tools:{
                         download: true,
                         zoomin: false,
                               zoomout: false,
                               reset: false,
                               pan: false,
                               zoom: false
                     },
                     export: {
                         png: {
                             filename: "undefined",
                           },
                           csv: {
                             filename: "undefined",
                           }
                     }
                   }
                },
                plotOptions: {
                    bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                legend: {
                 position: 'top',
                 horizontalAlign: 'left',
                 offsetX: 40
             },
                xaxis: {
                    categories: "year",
                    title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                style: {
                 fontSize: '12px',
                 color:'#76838f',
                 fontWeight: 100
               }}
                },
                
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                    formatter: function (val) {
                        return  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    }
                },
                theme: {
                 palette: 'palette3' // upto palette10
               },
               title: {
                 text: '',
                 align:'center',
                 style: {
                  fontSize: '12px',
                   fontWeight: 100,
                   color: 'rgb(55, 61, 63)'
                 }
               },
               subtitle: {
                 text: '(Click on bar to see details)',
                 align:'center',
               
               style: {
                fontSize: '12px',
                 fontWeight: 100,
                 color: 'rgb(55, 61, 63)'
               }},
                },
     
            }
           
        return barchart;
        case 'barchartdup':
         let barchartdup ={

                series: "serisdata",
                options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                     show: true,
                     tools:{
                         download: true,
                         zoomin: false,
                               zoomout: false,
                               reset: false,
                               pan: false,
                               zoom: false
                     },
                     export: {
                         png: {
                             filename: "undefined",
                           },
                           csv: {
                             filename: "undefined",
                           }
                     }
                   }
                },
                plotOptions: {
                    bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                legend: {
                 position: 'top',
                 horizontalAlign: 'left',
                 offsetX: 40
             },
                xaxis: {
                    categories: "year",
                    title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                style: {
                 fontSize: '12px',
                 color:'#76838f',
                 fontWeight: 100
               }}
                },
                
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                    formatter: function (val) {
                        return  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    }
                },
                theme: {
                 palette: 'palette3' // upto palette10
               },
               title: {
                 text: '',
                 align:'center',
                 style: {
                  fontSize: '12px',
                   fontWeight: 100,
                   color: 'rgb(55, 61, 63)'
                 }
               },
               subtitle: {
                 text: '(Click on bar to see details)',
                 align:'center',
               
               style: {
                fontSize: '12px',
                 fontWeight: 100,
                 color: 'rgb(55, 61, 63)'
               }},
                },
     
            }
           
        return barchartdup;
        case 'barchartdupdup':
         let barchartdupdup ={

                series: "serisdata",
                options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                     show: true,
                     tools:{
                         download: true,
                         zoomin: false,
                               zoomout: false,
                               reset: false,
                               pan: false,
                               zoom: false
                     },
                     export: {
                         png: {
                             filename: "undefined",
                           },
                           csv: {
                             filename: "undefined",
                           }
                     }
                   }
                },
                plotOptions: {
                    bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                legend: {
                 position: 'top',
                 horizontalAlign: 'left',
                 offsetX: 40
             },
                xaxis: {
                    categories: "year",
                    title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                style: {
                 fontSize: '12px',
                 color:'#76838f',
                 fontWeight: 100
               }}
                },
                
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                    formatter: function (val) {
                        return  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    }
                },
                theme: {
                 palette: 'palette3' // upto palette10
               },
               title: {
                 text: '',
                 align:'center',
                 style: {
                  fontSize: '12px',
                   fontWeight: 100,
                   color: 'rgb(55, 61, 63)'
                 }
               },
               subtitle: {
                 text: '(Click on bar to see details)',
                 align:'center',
               
               style: {
                fontSize: '12px',
                 fontWeight: 100,
                 color: 'rgb(55, 61, 63)'
               }},
                },
     
            }
           
        return barchartdupdup;
        case 'barchartdupduputtar':
         let barchartdupduputtar ={

                series: "serisdata",
                options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                     show: true,
                     tools:{
                         download: true,
                         zoomin: false,
                               zoomout: false,
                               reset: false,
                               pan: false,
                               zoom: false
                     },
                     export: {
                         png: {
                             filename: "undefined",
                           },
                           csv: {
                             filename: "undefined",
                           }
                     }
                   }
                },
                plotOptions: {
                    bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                legend: {
                 position: 'top',
                 horizontalAlign: 'left',
                 offsetX: 40
             },
                xaxis: {
                    categories: "year",
                    title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                style: {
                 fontSize: '12px',
                 color:'#76838f',
                 fontWeight: 100
               }}
                },
                
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                    formatter: function (val) {
                        return  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    }
                },
                theme: {
                 palette: 'palette3' // upto palette10
               },
               title: {
                 text: '',
                 align:'center',
                 style: {
                  fontSize: '12px',
                   fontWeight: 100,
                   color: 'rgb(55, 61, 63)'
                 }
               },
               subtitle: {
                 text: '(Click on bar to see details)',
                 align:'center',
               
               style: {
                fontSize: '12px',
                 fontWeight: 100,
                 color: 'rgb(55, 61, 63)'
               }},
                },
     
            }
           
        return barchartdupduputtar;
        case 'radar':
         let radar={ 

                    series: "serisdata",
                    options: {
                    chart: {
                        height: 350,
                        type: 'radar',
                        dropShadow: {
                        enabled: true,
                        blur: 1,
                        left: 1,
                        top: 1
                        },
                        toolbar: {
                        show: true,
                        tools:{
                            download: true,
                            zoomin: false,
                                    zoomout: false,
                                    reset: false,
                                    pan: false,
                                    zoom: false
                        },
                        export: {
                            png: {
                                filename: "undefined",
                            },
                            csv: {
                                filename: "undefined",
                            }
                        }
                    }
                    },
                    stroke: {
                        width: 2
                    },
                    fill: {
                        opacity: 0.1
                    },
                    markers: {
                        size: 0
                    },
                    legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                },
                    xaxis: {
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                        style: {
                        fontSize: '12px',
                        color:'#76838f',
                        fontWeight: 100
                    }}
                    },
                    theme: {
                    palette: 'palette4' // upto palette10
                },
                title: {
                    text: '',
                    align:'center',
                    style: {
                    fontSize: '12px',
                    fontWeight: 100,
                    color: 'rgb(55, 61, 63)'
                    }
                },
                subtitle: {
                    text: '(Click on bar to see details)',
                    align:'center',
                
                style: {
                    fontSize: '12px',
                    fontWeight: 100,
                    color: 'rgb(55, 61, 63)'
                }},
                    }
                
                
        }
        return radar;
        case 'radardup':
         let radardup={ 

                    series: "serisdata",
                    options: {
                    chart: {
                        height: 350,
                        type: 'radar',
                        dropShadow: {
                        enabled: true,
                        blur: 1,
                        left: 1,
                        top: 1
                        },
                        toolbar: {
                        show: true,
                        tools:{
                            download: true,
                            zoomin: false,
                                    zoomout: false,
                                    reset: false,
                                    pan: false,
                                    zoom: false
                        },
                        export: {
                            png: {
                                filename: "undefined",
                            },
                            csv: {
                                filename: "undefined",
                            }
                        }
                    }
                    },
                    stroke: {
                        width: 2
                    },
                    fill: {
                        opacity: 0.1
                    },
                    markers: {
                        size: 0
                    },
                    legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                },
                    xaxis: {
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                        style: {
                        fontSize: '12px',
                        color:'#76838f',
                        fontWeight: 100
                    }}
                    },
                    theme: {
                    palette: 'palette4' // upto palette10
                },
                title: {
                    text: '',
                    align:'center',
                    style: {
                    fontSize: '12px',
                    fontWeight: 100,
                    color: 'rgb(55, 61, 63)'
                    }
                },
                subtitle: {
                    text: '(Click on bar to see details)',
                    align:'center',
                
                style: {
                    fontSize: '12px',
                    fontWeight: 100,
                    color: 'rgb(55, 61, 63)'
                }},
                    }
                
                
        }
        return radardup;
        case 'heatmap':
         let heatmap={ 
        
            series: "serisdata",
            options: {
                chart: {
                  height: 450,
                  type: 'heatmap',
                  toolbar: {
                    show: true,
                    tools:{
                        download: true,
                        zoomin: false,
                              zoomout: false,
                              reset: false,
                              pan: false,
                              zoom: false
                    },
                    export: {
                        png: {
                            filename: "undefined",
                          },
                          csv: {
                            filename: "undefined",
                          }
                    }
                  }
                },
                dataLabels: {
                  enabled: false
                },
                //colors: colors,
                xaxis: {
                  type: 'category',
                  categories: "year",
                  title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
               style: {
                fontSize: '12px',
                color:'#76838f',
                fontWeight: 100
              }}
                },
                title: {
                  text: ''
                },
                grid: {
                  padding: {
                    right: 20
                  }
                },
                theme: {
                  palette: 'palette10' // upto palette10
                },
                title: {
                  text: '',
                  align:'center',
                  style: {
                   fontSize: '12px',
                    fontWeight: 100,
                    color: 'rgb(55, 61, 63)'
                  }
                },
                subtitle: {
                  text: '(Click on bar to see details)',
                  align:'center',
                
                style: {
                 fontSize: '12px',
                  fontWeight: 100,
                  color: 'rgb(55, 61, 63)'
                }},
              },
        }
        return heatmap;
        case 'heatmapdup':
            let heatmapdup={ 
           
               series: "serisdata",
               options: {
                   chart: {
                     height: 450,
                     type: 'heatmap',
                     toolbar: {
                       show: true,
                       tools:{
                           download: true,
                           zoomin: false,
                                 zoomout: false,
                                 reset: false,
                                 pan: false,
                                 zoom: false
                       },
                       export: {
                           png: {
                               filename: "undefined",
                             },
                             csv: {
                               filename: "undefined",
                             }
                       }
                     }
                   },
                   dataLabels: {
                     enabled: false
                   },
                   //colors: colors,
                   xaxis: {
                     type: 'category',
                     categories: "year",
                     title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                  style: {
                   fontSize: '12px',
                   color:'#76838f',
                   fontWeight: 100
                 }}
                   },
                   title: {
                     text: ''
                   },
                   grid: {
                     padding: {
                       right: 20
                     }
                   },
                   theme: {
                     palette: 'palette10' // upto palette10
                   },
                   title: {
                     text: '',
                     align:'center',
                     style: {
                      fontSize: '12px',
                       fontWeight: 100,
                       color: 'rgb(55, 61, 63)'
                     }
                   },
                   subtitle: {
                     text: '(Click on bar to see details)',
                     align:'center',
                   
                   style: {
                    fontSize: '12px',
                     fontWeight: 100,
                     color: 'rgb(55, 61, 63)'
                   }},
                 },
           }
           return heatmapdup;
        case 'heatmapdupdup':
            let heatmapdupdup={ 
           
               series: "serisdata",
               options: {
                   chart: {
                     height: 450,
                     type: 'heatmap',
                     toolbar: {
                       show: true,
                       tools:{
                           download: true,
                           zoomin: false,
                                 zoomout: false,
                                 reset: false,
                                 pan: false,
                                 zoom: false
                       },
                       export: {
                           png: {
                               filename: "undefined",
                             },
                             csv: {
                               filename: "undefined",
                             }
                       }
                     }
                   },
                   dataLabels: {
                     enabled: false
                   },
                   //colors: colors,
                   xaxis: {
                     type: 'category',
                     categories: "year",
                     title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                  style: {
                   fontSize: '12px',
                   color:'#76838f',
                   fontWeight: 100
                 }}
                   },
                   title: {
                     text: ''
                   },
                   grid: {
                     padding: {
                       right: 20
                     }
                   },
                   theme: {
                     palette: 'palette10' // upto palette10
                   },
                   title: {
                     text: '',
                     align:'center',
                     style: {
                      fontSize: '12px',
                       fontWeight: 100,
                       color: 'rgb(55, 61, 63)'
                     }
                   },
                   subtitle: {
                     text: '(Click on bar to see details)',
                     align:'center',
                   
                   style: {
                    fontSize: '12px',
                     fontWeight: 100,
                     color: 'rgb(55, 61, 63)'
                   }},
                 },
           }
           return heatmapdupdup;
        case 'heatmapdupduputtar':
         let heatmapdupduputtar={ 
        
            series: "serisdata",
            options: {
                chart: {
                  height: 450,
                  type: 'heatmap',
                  toolbar: {
                    show: true,
                    tools:{
                        download: true,
                        zoomin: false,
                              zoomout: false,
                              reset: false,
                              pan: false,
                              zoom: false
                    },
                    export: {
                        png: {
                            filename: "undefined",
                          },
                          csv: {
                            filename: "undefined",
                          }
                    }
                  }
                },
                dataLabels: {
                  enabled: false
                },
                //colors: colors,
                xaxis: {
                  type: 'category',
                  categories: "year",
                  title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
               style: {
                fontSize: '12px',
                color:'#76838f',
                fontWeight: 100
              }}
                },
                title: {
                  text: ''
                },
                grid: {
                  padding: {
                    right: 20
                  }
                },
                theme: {
                  palette: 'palette10' // upto palette10
                },
                title: {
                  text: '',
                  align:'center',
                  style: {
                   fontSize: '12px',
                    fontWeight: 100,
                    color: 'rgb(55, 61, 63)'
                  }
                },
                subtitle: {
                  text: '(Click on bar to see details)',
                  align:'center',
                
                style: {
                 fontSize: '12px',
                  fontWeight: 100,
                  color: 'rgb(55, 61, 63)'
                }},
              },
        }
        return heatmapdupduputtar;
        case 'scatter':
            let scatter = { 
                
                    series: "serisdata",
                    options: {
                        chart: {
                        height: 350,
                        type: 'scatter',
                        zoom: {
                            enabled: true,
                            type: 'xy'
                        },
                        toolbar: {
                            show: true,
                            tools:{
                                download: true,
                                zoomin: false,
                                zoomout: false,
                                reset: false,
                                pan: false,
                                zoom: false
                            },
                            export: {
                                png: {
                                    filename: "undefined",
                                },
                                csv: {
                                    filename: "undefined",
                                }
                            }
                        }
                        },
                        legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                        xaxis: {
                        /*tickAmount: 10,*/
                        labels: {
                            formatter: function(val) {
                                return val
                            }
                        },
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                style: {
                    fontSize: '12px',
                    color:'#76838f',
                    fontWeight: 100
                }}
                        },
                        yaxis: {
                        /*tickAmount: 7*/
                        },
                        theme: {
                        palette: 'palette7' // upto palette10
                        },
                        title: {
                        text: '',
                        align:'center',
                        style: {
                        fontSize: '12px',
                            fontWeight: 100,
                            color: 'rgb(55, 61, 63)'
                        }
                        },
                        subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                        
                        style: {
                        fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                        }},
                    },
            }
           return scatter;
        case 'scattersub':
            let scattersub = { 
                
                    series: "serisdata",
                    options: {
                        chart: {
                        height: 350,
                        type: 'scatter',
                        zoom: {
                            enabled: true,
                            type: 'xy'
                        },
                        toolbar: {
                            show: true,
                            tools:{
                                download: true,
                                zoomin: false,
                                zoomout: false,
                                reset: false,
                                pan: false,
                                zoom: false
                            },
                            export: {
                                png: {
                                    filename: "undefined",
                                },
                                csv: {
                                    filename: "undefined",
                                }
                            }
                        }
                        },
                        legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                        xaxis: {
                        /*tickAmount: 10,*/
                        labels: {
                            formatter: function(val) {
                                return val
                            }
                        },
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                style: {
                    fontSize: '12px',
                    color:'#76838f',
                    fontWeight: 100
                }}
                        },
                        yaxis: {
                        /*tickAmount: 7*/
                        },
                        theme: {
                        palette: 'palette7' // upto palette10
                        },
                        title: {
                        text: '',
                        align:'center',
                        style: {
                        fontSize: '12px',
                            fontWeight: 100,
                            color: 'rgb(55, 61, 63)'
                        }
                        },
                        subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                        
                        style: {
                        fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                        }},
                    },
            }
           return scattersub;
           case 'scattersubsub':
            let scattersubsub = { 
                
                    series: "serisdata",
                    options: {
                        chart: {
                        height: 350,
                        type: 'scatter',
                        zoom: {
                            enabled: true,
                            type: 'xy'
                        },
                        toolbar: {
                            show: true,
                            tools:{
                                download: true,
                                zoomin: false,
                                zoomout: false,
                                reset: false,
                                pan: false,
                                zoom: false
                            },
                            export: {
                                png: {
                                    filename: "undefined",
                                },
                                csv: {
                                    filename: "undefined",
                                }
                            }
                        }
                        },
                        legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                        xaxis: {
                        /*tickAmount: 10,*/
                        labels: {
                            formatter: function(val) {
                                return val
                            }
                        },
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                style: {
                    fontSize: '12px',
                    color:'#76838f',
                    fontWeight: 100
                }}
                        },
                        yaxis: {
                        /*tickAmount: 7*/
                        },
                        theme: {
                        palette: 'palette7' // upto palette10
                        },
                        title: {
                        text: '',
                        align:'center',
                        style: {
                        fontSize: '12px',
                            fontWeight: 100,
                            color: 'rgb(55, 61, 63)'
                        }
                        },
                        subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                        
                        style: {
                        fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                        }},
                    },
            }
           return scattersubsub;
        case 'scattersubsubdup':
            let scattersubsubdup = { 
                
                    series: "serisdata",
                    options: {
                        chart: {
                        height: 350,
                        type: 'scatter',
                        zoom: {
                            enabled: true,
                            type: 'xy'
                        },
                        toolbar: {
                            show: true,
                            tools:{
                                download: true,
                                zoomin: false,
                                zoomout: false,
                                reset: false,
                                pan: false,
                                zoom: false
                            },
                            export: {
                                png: {
                                    filename: "undefined",
                                },
                                csv: {
                                    filename: "undefined",
                                }
                            }
                        }
                        },
                        legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                        xaxis: {
                        /*tickAmount: 10,*/
                        labels: {
                            formatter: function(val) {
                                return val
                            }
                        },
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                style: {
                    fontSize: '12px',
                    color:'#76838f',
                    fontWeight: 100
                }}
                        },
                        yaxis: {
                        /*tickAmount: 7*/
                        },
                        theme: {
                        palette: 'palette7' // upto palette10
                        },
                        title: {
                        text: '',
                        align:'center',
                        style: {
                        fontSize: '12px',
                            fontWeight: 100,
                            color: 'rgb(55, 61, 63)'
                        }
                        },
                        subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                        
                        style: {
                        fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                        }},
                    },
            }
           return scattersubsubdup;
        case 'scattersubsubduputtar':
            let scattersubsubduputtar = { 
                
                    series: "serisdata",
                    options: {
                        chart: {
                        height: 350,
                        type: 'scatter',
                        zoom: {
                            enabled: true,
                            type: 'xy'
                        },
                        toolbar: {
                            show: true,
                            tools:{
                                download: true,
                                zoomin: false,
                                zoomout: false,
                                reset: false,
                                pan: false,
                                zoom: false
                            },
                            export: {
                                png: {
                                    filename: "undefined",
                                },
                                csv: {
                                    filename: "undefined",
                                }
                            }
                        }
                        },
                        legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                        xaxis: {
                        /*tickAmount: 10,*/
                        labels: {
                            formatter: function(val) {
                                return val
                            }
                        },
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',
                style: {
                    fontSize: '12px',
                    color:'#76838f',
                    fontWeight: 100
                }}
                        },
                        yaxis: {
                        /*tickAmount: 7*/
                        },
                        theme: {
                        palette: 'palette7' // upto palette10
                        },
                        title: {
                        text: '',
                        align:'center',
                        style: {
                        fontSize: '12px',
                            fontWeight: 100,
                            color: 'rgb(55, 61, 63)'
                        }
                        },
                        subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                        
                        style: {
                        fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                        }},
                    },
            }
           return scattersubsubduputtar;
        case 'pie':
            let pie={ 
                
                series: "serisdata",
                options: {
                    chart: {
                      width: 380,
                      type: 'pie',
                    },
                    labels: "year",
                    responsive: [{
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 200
                        },
                        legend: {
                          position: 'top',
                          horizontalAlign: 'left',
                          offsetX: 40
                      },
                      }
                    }]
                  },
            }
            return pie;
        case 'area':
            let area={
                    series: "serisdata",
                    options: {
                      chart: {
                        height: '365px',
                        type: 'area',
                        toolbar: {
                          show: true,
                          tools:{
                              download: true,
                              zoomin: false,
                              zoomout: false,
                              reset: false,
                              pan: false,
                              zoom: false
                          },
                          export: {
                              png: {
                                  filename: "undefined",
                                },
                                csv: {
                                  filename: "undefined",
                                }
                          }
                        }
                      },
                      dataLabels: {
                        enabled: false
                      },
                      stroke: {
                        curve: 'smooth'
                      },
                      xaxis: {
                        type: 'number',
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',align:'right',
                        style: {
                          fontSize: '12px',
                          color:'#76838f',
                          fontWeight: 100
                        }}
                      },
                      legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                      theme: {
                        palette: 'palette6' // upto palette10
                      },
                      title: {
                        text: '',
                        align:'center',
                        style: {
                         fontSize: '12px',
                          fontWeight: 100,
                          color: 'rgb(55, 61, 63)'
                        }
                      },
                      subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                      
                      style: {
                       fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                      }},
                    },
                  }
    
        return area;
        case 'areadup':
            let areadup={
                    series: "serisdata",
                    options: {
                      chart: {
                        height: '365px',
                        type: 'area',
                        toolbar: {
                          show: true,
                          tools:{
                              download: true,
                              zoomin: false,
                              zoomout: false,
                              reset: false,
                              pan: false,
                              zoom: false
                          },
                          export: {
                              png: {
                                  filename: "undefined",
                                },
                                csv: {
                                  filename: "undefined",
                                }
                          }
                        }
                      },
                      dataLabels: {
                        enabled: false
                      },
                      stroke: {
                        curve: 'smooth'
                      },
                      xaxis: {
                        type: 'number',
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',align:'right',
                        style: {
                          fontSize: '12px',
                          color:'#76838f',
                          fontWeight: 100
                        }}
                      },
                      legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                      theme: {
                        palette: 'palette6' // upto palette10
                      },
                      title: {
                        text: '',
                        align:'center',
                        style: {
                         fontSize: '12px',
                          fontWeight: 100,
                          color: 'rgb(55, 61, 63)'
                        }
                      },
                      subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                      
                      style: {
                       fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                      }},
                    },
                  }
    
        return areadup;
        case 'areadupdup':
            let areadupdup={
                    series: "serisdata",
                    options: {
                      chart: {
                        height: '365px',
                        type: 'area',
                        toolbar: {
                          show: true,
                          tools:{
                              download: true,
                              zoomin: false,
                              zoomout: false,
                              reset: false,
                              pan: false,
                              zoom: false
                          },
                          export: {
                              png: {
                                  filename: "undefined",
                                },
                                csv: {
                                  filename: "undefined",
                                }
                          }
                        }
                      },
                      dataLabels: {
                        enabled: false
                      },
                      stroke: {
                        curve: 'smooth'
                      },
                      xaxis: {
                        type: 'number',
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',align:'right',
                        style: {
                          fontSize: '12px',
                          color:'#76838f',
                          fontWeight: 100
                        }}
                      },
                      legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                      theme: {
                        palette: 'palette6' // upto palette10
                      },
                      title: {
                        text: '',
                        align:'center',
                        style: {
                         fontSize: '12px',
                          fontWeight: 100,
                          color: 'rgb(55, 61, 63)'
                        }
                      },
                      subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                      
                      style: {
                       fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                      }},
                    },
                  }
    
        return areadupdup;
        case 'areadupdupdup':
            let areadupdupdup={
                    series: "serisdata",
                    options: {
                      chart: {
                        height: '365px',
                        type: 'area',
                        toolbar: {
                          show: true,
                          tools:{
                              download: true,
                              zoomin: false,
                              zoomout: false,
                              reset: false,
                              pan: false,
                              zoom: false
                          },
                          export: {
                              png: {
                                  filename: "undefined",
                                },
                                csv: {
                                  filename: "undefined",
                                }
                          }
                        }
                      },
                      dataLabels: {
                        enabled: false
                      },
                      stroke: {
                        curve: 'smooth'
                      },
                      xaxis: {
                        type: 'number',
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',align:'right',
                        style: {
                          fontSize: '12px',
                          color:'#76838f',
                          fontWeight: 100
                        }}
                      },
                      legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                      theme: {
                        palette: 'palette6' // upto palette10
                      },
                      title: {
                        text: '',
                        align:'center',
                        style: {
                         fontSize: '12px',
                          fontWeight: 100,
                          color: 'rgb(55, 61, 63)'
                        }
                      },
                      subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                      
                      style: {
                       fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                      }},
                    },
                  }
    
        return areadupdupdup;
        case 'areadupdupduputtar':
            let areadupdupduputtar={
                    series: "serisdata",
                    options: {
                      chart: {
                        height: '365px',
                        type: 'area',
                        toolbar: {
                          show: true,
                          tools:{
                              download: true,
                              zoomin: false,
                              zoomout: false,
                              reset: false,
                              pan: false,
                              zoom: false
                          },
                          export: {
                              png: {
                                  filename: "undefined",
                                },
                                csv: {
                                  filename: "undefined",
                                }
                          }
                        }
                      },
                      dataLabels: {
                        enabled: false
                      },
                      stroke: {
                        curve: 'smooth'
                      },
                      xaxis: {
                        type: 'number',
                        categories: "year",
                        title:{text:'Source: Global Market Insights Inc. © All Rights Reserved',align:'right',
                        style: {
                          fontSize: '12px',
                          color:'#76838f',
                          fontWeight: 100
                        }}
                      },
                      legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
                      theme: {
                        palette: 'palette6' // upto palette10
                      },
                      title: {
                        text: '',
                        align:'center',
                        style: {
                         fontSize: '12px',
                          fontWeight: 100,
                          color: 'rgb(55, 61, 63)'
                        }
                      },
                      subtitle: {
                        text: '(Click on bar to see details)',
                        align:'center',
                      
                      style: {
                       fontSize: '12px',
                        fontWeight: 100,
                        color: 'rgb(55, 61, 63)'
                      }},
                    },
                  }
    
        return areadupdupduputtar;

        default:
          return 'foo';
      }

  

}
    