import ReactApexChart from 'react-apexcharts';
import React, { useState, useEffect } from 'react'
import Nav2 from './MyUICompo/Nav2'
import Nav from './MyUICompo/Nav'
import CagrBoxCompo from './CagrBoxCompo'
import TableCompo from './TableCompo'
import {charttype} from './ChartTypeJson'
import SideMenuBar from './SideMenuBar'
 
const ChartCompo = (props) => {

  let [apiResponse, setapiResponse] = useState([]);
  let [DataisLoaded, setDataisLoaded] = useState(false); 
  let [Barchartsatate, setBarchartsatate] = useState([]);
  let [report_title, setreport_title] = useState();
  let [cagrdata, setcagrdata] = useState(false);
   let [jsondata, setjsondata] = useState('');
   
    function callbar(data) {
  
    
       let data_extract = data._RegionData;
       let cagr_data=data._RegionData[0];
       
    
       let listItems = data_extract.map((item, index, dataarray) => {
   
          
         let count_array = dataarray[index].tabledata.length;
         let optionsString;
             let seriesString;
             let currentChartType;
              
               charttype.map((charttype, chartindex,chartArray) => {
   
                 Object.entries(chartArray[chartindex]).map(([key,subindex])=>{
                   
                   
                   if(dataarray[index].chartTyape==key){
                     console.log(dataarray[index].chartTyape);
                      chartArray[chartindex][key].series=dataarray[index].tabledata;
                      chartArray[chartindex][key].options.xaxis.categories=dataarray[index].year;
                       
                     optionsString =  chartArray[chartindex][key].options;
                     seriesString = chartArray[chartindex][key].series;
                     currentChartType=chartArray[chartindex][key].options.chart.type;
                     
   
                       
                   }
    
                   
               })
               
   
               }); 
   
               return (
                 <div className="col-sm-6 grid-margin grid-margin-md-0 stretch-card mt-4">
                   <div className="card">
                     <div className="card-body">
                       <div className="d-flex align-items-center justify-content-between">
                         <h4 className="card-title">{dataarray[index].Heading.substring(dataarray[index].Heading.indexOf("Estimates & Forecast,") + 21)}</h4>
                         <h4 className="text-success font-weight-bold">USD Millions</h4>
                       </div>
     
                       <div id="support-tracker-legend" className="support-tracker-legend"></div>
     
                       <ReactApexChart options={optionsString} series={seriesString} type={currentChartType} height={350} />
                     </div>
                   </div>
                 </div>)
   
         
   
          
          
       }
   
       );
   
    
       setapiResponse(data);
       setDataisLoaded(true);
       setBarchartsatate(listItems);
      // setreport_title(data_extract[0].Heading);
       setcagrdata(cagr_data);
     }

 /* useEffect(  () => {

   

     fetch("https://api.gmipulse.in/RegionsLandingData/62cf10e39d34e7bab96f3e11")
     .then(res => res.json())
     .then((json) => callbar(json));
     

   }, [])*/

 
 
    
     
    if (!props.chartdata) return <div>
      <h1>Data is Loading...  </h1> </div>;
    return (
      <div>
        
        <div className="mixed-chart">
          <div className="container-fluid page-body-wrapper">
          
            <div className="main-panel">
              <div className="content-wrapper">
              
             
                <div className="row">
                  {
                     
                    
        props.chartdata._RegionData.map((item, index, dataarray) => {
   
           
       
         let count_array = dataarray[index].tabledata.length;
         let optionsString;
             let seriesString;
             let currentChartType;
              
               charttype.map((charttype, chartindex,chartArray) => {
   
                 Object.entries(chartArray[chartindex]).map(([key,subindex])=>{
                   
                   
                   if(dataarray[index].chartTyape==key){
                     console.log(dataarray[index].chartTyape);
                      chartArray[chartindex][key].series=dataarray[index].tabledata;
                      chartArray[chartindex][key].options.xaxis.categories=dataarray[index].year;
                       
                     optionsString =  chartArray[chartindex][key].options;
                     seriesString = chartArray[chartindex][key].series;
                     currentChartType=chartArray[chartindex][key].options.chart.type;
                     
   
                       
                   }
    
                   
               })
               
   
               }); 
   
              return(
               <div className="col-sm-6 grid-margin grid-margin-md-0 stretch-card mt-4">
               <div className="card">
                 <div className="card-body">
                   <div className="d-flex align-items-center justify-content-between">
                     <p>{dataarray[index].Heading}</p>
                      
                   </div>
 
                   <div id="support-tracker-legend" className="support-tracker-legend"></div>
 
                   <ReactApexChart options={optionsString} series={seriesString} type={currentChartType} height={350} />
                 </div>
               </div>
             </div>)
         
   
          
          
       }
   
       )}
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>




    );


  
}

 

export default ChartCompo;