import ReactApexChart from 'react-apexcharts';
import React, { useState,useEffect } from 'react'
import { getRegionDataByID, getCountryDataByID } from "../api-services/AllAPI";
import {charttype} from './ChartTypeJson'
import MonthAndWeekCompo from './MonthAndWeekCompo'
import Daterange from './Daterange'
const AllData = (props) =>{


    

    let [apiResponse, setapiResponse] = useState([]);
    let [DataisLoaded, setDataisLoaded] = useState(false); 
    let [Barchartsatate, setBarchartsatate] = useState([]);
    let [report_title, setreport_title] = useState();

    let [showingdata, setshowingdata] = useState(" ");


    let response;
  useEffect(  () => {
    async function  callbar() {

   if(props.countryID){
       
        response = await getCountryDataByID(props.countryID);
       
       
   }else{
        
       
          response =   await getRegionDataByID(props.resgionID);
         
   }


   
  if(response.coutrydata===""){
    setDataisLoaded(true);
    setBarchartsatate("Select another data.");
    setshowingdata("");
  }
    
   let data_extract='';
   let cagr_data='';
   let rtitle;



    if(props.countryID){
      data_extract = response.coutrydata;
      rtitle=response.coutrydata[0].Heading;
      //setshowingdata(rtitle);
      
    }else{
      data_extract = response._RegionData;
      cagr_data=response._RegionData[0];
      rtitle=cagr_data.Heading;
       
    }

    console.log("TESTING HERE"+data_extract);


    function sortfinaldata(dataarray, index){
 
      let count_array = dataarray[index].tabledata.length;
     
      let optionsString;
          let seriesString;
          let currentChartType;
           let acyualseries;
           let titlenamefirst=dataarray[index].Heading.substr(0, 70).lastIndexOf(' ');
           let titlename=dataarray[index].Heading.substr(0, titlenamefirst);
           let subtitlename=dataarray[index].Heading.substr(titlenamefirst);
            charttype.map((charttype, chartindex,chartArray) => {

              Object.entries(chartArray[chartindex]).map(([key,subindex])=>{
                
                
                if(dataarray[index].chartTyape==key){
                  //console.log(dataarray[index].chartTyape);
                   chartArray[chartindex][key].series=dataarray[index].tabledata;
                   //chartArray[chartindex][key].options.title.text=dataarray[index].Heading;
                   chartArray[chartindex][key].options.title.text=titlename;
                   chartArray[chartindex][key].options.subtitle.text=subtitlename;
                   chartArray[chartindex][key].options.chart.toolbar.export.png.filename=dataarray[index].Heading;
                   chartArray[chartindex][key].options.chart.toolbar.export.csv.filename=dataarray[index].Heading;
                  //console.log(chartArray[chartindex][key].series);
                  chartArray[chartindex][key].options.forecastDataPoints.count=dataarray[index].forecastDataPoints;
                  if(dataarray[index].chartTyape=="pie"){
                    chartArray[chartindex][key].options.labels=dataarray[index].year;
                    //console.log(chartArray[chartindex][key].options.labels);
                  }else{
                    chartArray[chartindex][key].options.xaxis.categories=dataarray[index].year; 
                  }
                  
                  optionsString= chartArray[chartindex][key].options;
                  
                  seriesString= chartArray[chartindex][key].series;
                  currentChartType=chartArray[chartindex][key].options.chart.type;
                  if(count_array=='1')
                  { 
                      acyualseries=seriesString;
                  }else
                  {acyualseries=seriesString.slice(0, count_array-1);}
                }
 
                
            })
            
            }); 
           

            return (
                <>
                <h3>{dataarray[index].sectionName}</h3>
              <div className="col-sm-6 grid-margin grid-margin-md-0 stretch-card mt-2">
                <div className="card">
                  <div className="card-body">
                    
                  <p class="noselect" style={{color:'transparent'}}>{dataarray[index].Heading}</p>
                    <div id="support-tracker-legend" className="support-tracker-legend"></div>
   
                    <ReactApexChart options={optionsString} series={acyualseries} type={currentChartType} height={365}  />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 grid-margin grid-margin-md-0 stretch-card mt-2">
                <div className="card">
                  <div className="card-body append-html">
                    
                  
                    <div id="support-tracker-legend" className="support-tracker-legend"></div>

                    <div dangerouslySetInnerHTML={{__html: dataarray[index].content}} />
                    
                     
                  </div>
                </div>
              </div><div className="col-sm-12 grid-margin grid-margin-md-0 stretch-card mt-2 mb-5">
                        <div className="card">
                            <div className="card-body table-hr-scroll">
                            
                                <div className="d-flex align-items-center justify-content-between">
                                    <h3>{dataarray[index].Heading}</h3>
                                    <a href="https://gmipulse.gminsights.com/admin/products/excelDownload?file=30891662318388&id=3457&title=Key Pricing Insights - 4 Product" className="btn btn-inverse-primary btn-sm" style={{'marginBottom':"10px"}}>Download Excel</a>
                                     
                                </div>
        
                                <div id="support-tracker-legend" className="support-tracker-legend"></div>
                                <div dangerouslySetInnerHTML={{__html: dataarray[index].tablecontent}} />      
                               
                            </div>
                        </div>
                    </div></>)


    }
       
    let listItems=[];
    let flag=0;
    let flagall=0;
    let flagmonth=0;
    let flagweek=0;

     
       data_extract.map((item, index, dataarray) => {

        console.log(dataarray[index].Heading);
        
      if(data_extract[index].product===props.prodtype && props.month === " " && props.week===" "){
       setshowingdata(dataarray[index].Heading);
         listItems[flag] = sortfinaldata(dataarray, index);
          flag=flag+1;
            }else if(data_extract[index].product===props.prodtype && props.month===data_extract[index].pmonth && props.week===data_extract[index].week){
              //setshowingdata(props.month+" & Week "+props.week);
              listItems[flagall] = sortfinaldata(dataarray, index);
              flagall=flagall+1;

            }else if(data_extract[index].product===props.prodtype && props.month===data_extract[index].pmonth){
              listItems[flagmonth] = sortfinaldata(dataarray, index);
              //setshowingdata(props.month);
              flagmonth=flagmonth+1;

            }else if(data_extract[index].product===props.prodtype && props.week===data_extract[index].week){
              //setshowingdata("Week "+props.week);
              listItems[flagweek] = sortfinaldata(dataarray, index);
              flagweek=flagweek+1;

            } else{

                return("")
            }

    } 

    );

 

    setapiResponse(response);
            setDataisLoaded(true);
            setBarchartsatate(listItems);
            setreport_title(data_extract[0].Heading);

            
  }
  setBarchartsatate('');
   
  callbar();
  }, [props.countryID,props.resgionID,props.prodtype,props.week,props.month])

  

  if (!DataisLoaded || !Barchartsatate) return(

      <div className="container-fluid" style={{'background': "#f0f3f6", 'textAlign': "center" }}>
            <div className="row" style={{'display': "block", 'text-align': "center" }}>
            <div >
   <img style={{'height': '4rem' }} src="/myCSS/images/loader.gif" alt="image" className="profile-pic" /> </div>
            </div>
      </div>
    
   )
return (
  
        
            <div className="row" >
           
           <div className="col-md-6">
            <h3>{showingdata}</h3>
          </div>
            {showingdata!==""?
            <Daterange /> : ''
          }
            
              {Barchartsatate}
            </div>
            
          




);
}


 

export default AllData;