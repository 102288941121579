import react, {useState, useEffect} from 'react'
import AllChartCompo from './AllChartCompo'
import AllTableCompo from './AllTableCompo'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,useNavigate
} from "react-router-dom";
import AllData from './AllData'

const SideMenuBarDemo = (props) => {
 
   
  const [countrysate, setcountrysate]=useState('');
  const [disabled, setDisabled] = useState(props.name.replace(/\s+/g, '-').toLowerCase());
  const [isVisualz, setisVisualz] = useState(false);
  const [uri, seturi] = useState('');
  let [DataisLoaded, setDataisLoaded] = useState(false); 
  const [vtype, setvtype] = useState(props.ctype); 
  const [resgionId, setresgionId] =useState('');
  const [countryiD, setcountryiD] =useState('');
  const [commanflag, setcommanflag] =useState('');

  const [breadcrumbregion, setbreadcrumbregion] =useState('');
  const [breadcrumbvis, setbreadcrumbvis] =useState('');
  const [breadcrumbcountry, setbreadcrumbcountry] =useState('charts');

  const [iscountryexist, setiscountryexist] =useState(false);
  
  const nav = useNavigate();
  //const [enableglobal, setenableglobal] = useState(true)
  let country_check_box;
  let chartDatavar="";

  /*const [countryname, setcname] = useState(props.cname);
  if(countryname){
 
      console.log((props.cname);
      setcommanflag('countrydata');
      setbreadcrumbcountry(props.cname+" > ");
      setcountryiD(props.countryID);
      seturi('/'+props.reportID+'/'+props.name.replace(/\s+/g, '-').toLowerCase()+'/'+props.cname.replace(/\s+/g, '-').toLowerCase()+'/');
       
  
   
  } */
  function _onchangeResgionHandler(_resgionid, _reportid, _rname) {
    
   setDisabled(_rname);
   setcountrysate('');
   setiscountryexist(true)
   country_check_box = props.regiondata.map((rdata1) =>
      rdata1._id==_resgionid?
        rdata1.mapping.length > 0 ?  
              rdata1.mapping.map(countrymenu =>
                  <div className="form-check">
                  <label className="form-check-label">
                    <input type="radio" name="countryRadios"  className="form-check-input"  onChange={()=>_onCountryCheckBoxClick(countrymenu._id,_reportid,countrymenu.cname,_rname)} />
                    {countrymenu.cname}
              <i className="input-helper"></i><i className="input-helper"></i>
                  </label>
                </div>
                    )
          : setiscountryexist(false)
      :''
   )
 
   
   setDataisLoaded(false);
   setcountrysate(country_check_box);
   setresgionId(_resgionid);
   setcommanflag('regiondata');
   setbreadcrumbregion(_rname+" > ");
   setbreadcrumbcountry('');
    seturi('/'+_reportid+'/'+_rname+'/');
    nav('/'+_reportid+'/'+_rname+'/'+vtype);
   
    setbreadcrumbvis(vtype);

  
  }




 
  function _onCountryCheckBoxClick(cuntryID,rid,cname,rname){

    setcommanflag('countrydata');
    setbreadcrumbcountry(cname+" > ");
    setcountryiD(cuntryID);
    seturi('/'+rid+'/'+rname+'/'+cname.replace(/\s+/g, '-').toLowerCase()+'/');
    nav('/'+rid+'/'+rname+'/'+cname.replace(/\s+/g, '-').toLowerCase()+'/'+vtype);

  }
  
  function callApi(data){


    console.log(data._RegionData.length);
    if(data._RegionData.length){
      
     setDataisLoaded(true);
    }
   
    
     
  }
  let  datalen;

  function getDefaultCheckedData(defaultRegionID,rid, rname){

     
   
    if(resgionId.length == 0 ){

      setcommanflag('regiondata');
     setresgionId(defaultRegionID);
     setDataisLoaded(false);
     seturi('/'+rid+'/'+rname+'/');
     setbreadcrumbregion(rname+" > ");


    }

  
      
     
  }
  let   resgiond;
 
  
  if (props.regiondata) {

     
    
     resgiond= props.regiondata.map((rdata) =>

      

     <div className="form-check">
        <label className="form-check-label">
        {
           
          disabled==rdata.name.replace(/\s+/g, '-').toLowerCase()? getDefaultCheckedData(rdata._id,rdata.reportid, rdata.name.replace(/\s+/g, '-').toLowerCase()):''
        }
        
         <input type="radio" name="optionsRadios" id={rdata._id} checked={disabled ===  rdata.name.replace(/\s+/g, '-').toLowerCase()} className="form-check-input"   onChange={(e) => _onchangeResgionHandler(rdata._id, rdata.reportid, rdata.name.replace(/\s+/g, '-').toLowerCase())} /> 
          
         
          {rdata.name.replace(/\b(\w)/g, s => s.toUpperCase())}
          <i className="input-helper"></i><i className="input-helper"></i></label>
      
      </div>

    )
  
  }

  function _onvisualitazionChange(vitype){
    
    setbreadcrumbvis(vitype);
    setvtype(vitype);
    nav(uri+vitype);
    

  }

  if (!DataisLoaded && resgionId.length ==0) return( 
  <div className="container-fluid" style={{'background': "#f0f3f6", 'text-align': "center" }}>
            <div className="row" style={{'display': "block", 'text-align': "center" }}>
            <div >
   <img style={{'height': '4rem' }} src="/myCSS/images/loader.gif" alt="image" className="profile-pic" /> </div>
            </div>
      </div>)
  return (
    <div>
      <div className="container-fluid page-body-wrapper">
        <div className="col-md-2 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <p className="card-description">To change Visualization please select below actions</p>
              <p className="card-description">Select Region</p>
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      {resgiond}
                    </div>
                  </div>

                </div>
              </form>
              <p className="card-description">Visualization</p>
              <form>
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="form-group">
                                          <div class="form-check">
                                              <label class="form-check-label">
                                                <input type="radio" name="optionsRadios" value='alldata'  checked={vtype==="alldata"} onChange={(e) => _onvisualitazionChange(e.target.value)}class="form-check-input"/>
                                                All Data
                                              <i class="input-helper"></i><i class="input-helper"></i><i class="input-helper"></i></label>
                                            </div>
                                            <div class="form-check">
                                              <label class="form-check-label">
                                                <input type="radio" name="optionsRadios" class="form-check-input" checked={vtype==="charts"} value='charts' onChange={(e) => _onvisualitazionChange(e.target.value)} />
                                                
                                                Chart
                                              <i class="input-helper"></i><i class="input-helper"></i><i class="input-helper"></i></label>
                                            </div>
                                            <div class="form-check">
                                              <label class="form-check-label">
                                                <input type="radio" name="optionsRadios" value='tables'  checked={vtype==="tables"} onChange={(e) => _onvisualitazionChange(e.target.value)}class="form-check-input"/>
                                                Table
                                              <i class="input-helper"></i><i class="input-helper"></i><i class="input-helper"></i></label>
                                            </div>
                                            
                                          </div>
                                        </div>

                                      </div>
                                    </form>
               {/*console.log(iscountryexist)*/}
               {  iscountryexist ? <p className="card-description">Select Country</p> :'' }
               <p className="card-description">Select Country</p>
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      
                   {country_check_box = props.regiondata.map((rdata1) =>
      
        rdata1.mapping.length > 0 ?  
              rdata1.mapping.map(countrymenu =>
                  <div className="form-check">
                  <label className="form-check-label">
                    <input type="radio" name="countryRadios" checked  className="form-check-input" />
                    {countrymenu.cname}
              <i className="input-helper"></i><i className="input-helper"></i>
                  </label>
                </div>
                    )
          : setiscountryexist(false)
      
   )}


                    </div>
                  </div>

                </div>
              </form>
            </div>

          </div>
        </div>
        {
vtype=="charts"? commanflag=="regiondata"? resgionId.length > 0 ? <AllChartCompo breadcrumb={breadcrumbregion.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} resgionID={resgionId} />:console.log('wrong'):'':''
        }
        {
 vtype=="tables"?  commanflag=="regiondata"? resgionId.length > 0 ? <AllTableCompo  breadcrumb={breadcrumbregion.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} resgionID={resgionId} />:console.log('wrong'):'':''
        }
         {
vtype=="charts"?  commanflag=="countrydata"? countryiD.length > 0 ? <AllChartCompo  breadcrumb={breadcrumbregion.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} countryID={countryiD} />:console.log('wrong'):'':''
        }
        {
vtype=="tables"?  commanflag=="countrydata"?  countryiD.length > 0 ? <AllTableCompo  breadcrumb={breadcrumbregion.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} countryID={countryiD} />:console.log('wrong'):'':''
        }
        {
vtype=="alldata"?  commanflag=="countrydata"?  countryiD.length > 0 ? <AllData  breadcrumb={breadcrumbregion.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} countryID={countryiD} />:console.log('wrong'):'':''
        }
        {
vtype=="alldata"? commanflag=="regiondata"? resgionId.length > 0 ? <AllData breadcrumb={breadcrumbregion.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbcountry.replace(/\b(\w)/g, s => s.toUpperCase())+breadcrumbvis.replace(/\b(\w)/g, s => s.toUpperCase())} resgionID={resgionId} />:console.log('wrong'):'':''
        }
       
      </div>

    </div>
  );
}

export default SideMenuBarDemo;