import ReactApexChart from 'react-apexcharts';
import react, { useState, useEffect } from 'react'
import { getRegionDataByID, getCountryDataByID } from "../api-services/AllAPI";
//import {CSVLink, CSVDownload} from 'react-csv';

const AllTableCompo = (props) =>{

    let [apiResponse, setapiResponse] = useState([]);
  let [DataisLoaded, setDataisLoaded] = useState(false); 
  let [Barchartsatate, setBarchartsatate] = useState([]);
  let [report_title, setreport_title] = useState();

  function xxval(val){
   
    var num=  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    var n = num.replace(new RegExp("[0-9]", "g"), "x"); 
   return n
  
  }
 
      
    useEffect(  () => {
 
        async function callbar() {
 
               let response;
              
                if(props.countryID){
                    
                     response = await getCountryDataByID(props.countryID);
                    
                }else{
                     response = await getRegionDataByID(props.resgionID);
                    
                }
            
           let data_extract='';
           if(props.countryID){
             data_extract = response.coutrydata;
             
           }else{
             data_extract = response._RegionData;
           }
         
            

           
        
           
            // console.log(data_extract);
        
        
        
            // return ( <ReactApexChart options={barchart1.options} series={barchart1.series} type="bar" height={350} />);
        
           let csvData
            let listItems = data_extract.map((item, index, dataarray) => {
        
            
                 
                return (
                    <div className="col-sm-12 grid-margin grid-margin-md-0 stretch-card mt-2">
                        <div className="card">
                            <div className="card-body table-hr-scroll">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h3>{dataarray[index].Heading}</h3>
                                    
                                     
                                </div>
        
                                <div id="support-tracker-legend" className="support-tracker-legend"></div>
                                <div dangerouslySetInnerHTML={{__html: dataarray[index].tablecontent}} /> 
        
                               
                            </div>
                        </div>
                    </div>)
        
        
        
            }
        
            );
        
        
            
            setapiResponse(response);
            setDataisLoaded(true);
            setBarchartsatate(listItems);

            console.log(data_extract[0].Heading);
            setreport_title(data_extract[0].Heading);
            
        }
       callbar();
       setBarchartsatate('');
      
      
    }, [props.resgionID,props.countryID])


if (!DataisLoaded || !Barchartsatate)  return( <div className="container-fluid" style={{'background': "#f0f3f6", 'text-align': "center" }}>
<div className="row" style={{'display': "block", 'text-align': "center" }}>
<div >
<img style={{'height': '4rem' }} src="/myCSS/images/loader.gif" alt="image" className="profile-pic" /> </div>
</div>
</div>)
return (
    <div className="col-md-10 grid-margin">
                 
                <div className="mixed-chart">
                    <div className="container-fluid page-body-wrapper">
                        <div className="main-panel">
                            <div className="content-wrapper">
                
                            
                                <div className="row">
                                    {Barchartsatate}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


);




}

 


export default AllTableCompo;