import ReactApexChart from 'react-apexcharts';
import React, { useState,useEffect } from 'react'
import { getRegionDataByID, getCountryDataByID } from "../api-services/AllAPI";
import {charttype} from './ChartTypeJson'
  
const AllChartCompo = (props) =>{

    let [apiResponse, setapiResponse] = useState([]);
    let [DataisLoaded, setDataisLoaded] = useState(false); 
    let [Barchartsatate, setBarchartsatate] = useState([]);
    let [report_title, setreport_title] = useState();
    let response;
  useEffect(  () => {
    async function  callbar() {

   
              
   if(props.countryID){
       
        response = await getCountryDataByID(props.countryID);
       
       
   }else{
        
       
          response =   await getRegionDataByID(props.resgionID);
         
   }


   

   //console.log(response);

    
   let data_extract='';
   let cagr_data='';
   let rtitle;



    if(props.countryID){
      data_extract = response.coutrydata;
      rtitle=response.coutrydata[0].Heading;
      
    }else{
      data_extract = response._RegionData;
      cagr_data=response._RegionData[0];
      rtitle=cagr_data.Heading;
       
    }
       
   
   
   
     
   
    let listItems = data_extract.map((item, index, dataarray) => {

       
      let count_array = dataarray[index].tabledata.length;
     
      let optionsString;
          let seriesString;
          let currentChartType;
           let acyualseries;
           let titlenamefirst=dataarray[index].Heading.substr(0, 70).lastIndexOf(' ');
           let titlename=dataarray[index].Heading.substr(0, titlenamefirst);
           let subtitlename=dataarray[index].Heading.substr(titlenamefirst);

           let chartt= dataarray[index].chartTyape;
            charttype.map((charttype, chartindex,chartArray) => {

              Object.entries(chartArray[chartindex]).map(([key,subindex])=>{
               
                
                if(dataarray[index].chartTyape==key){
                  console.log(chartt);
                  //console.log(dataarray[index].chartTyape);
                   chartArray[chartindex][key].series=dataarray[index].tabledata;
                   //chartArray[chartindex][key].options.title.text=dataarray[index].Heading;
                   chartArray[chartindex][key].options.title.text=titlename;
                   chartArray[chartindex][key].options.subtitle.text=subtitlename;
                   chartArray[chartindex][key].options.chart.toolbar.export.png.filename=dataarray[index].Heading;

                  //chartArray[chartindex][key].options.forecastDataPoints.count=dataarray[index].forecastDataPoints;

                   
                  //console.log(chartArray[chartindex][key].series);
                  if(dataarray[index].chartTyape=="pie"){
                    chartArray[chartindex][key].options.labels=dataarray[index].year;
                    //console.log(chartArray[chartindex][key].options.labels);
                  }else{
                    chartArray[chartindex][key].options.xaxis.categories=dataarray[index].year; 
                  }
                  optionsString= chartArray[chartindex][key].options;
                  seriesString= chartArray[chartindex][key].series;
                  currentChartType=chartArray[chartindex][key].options.chart.type;
                  
                      acyualseries=seriesString;
               
                  
                }
 
                
            })
            
            }); 

            return (
              <div className="col-sm-6 grid-margin grid-margin-md-0 stretch-card mt-2">
                <div className="card">
                  <div className="card-body">
                    
                  <p class="noselect" style={{color:'transparent'}}>{dataarray[index].Heading}</p>
                    <div id="support-tracker-legend" className="support-tracker-legend"></div>
                    <ReactApexChart options={optionsString} series={acyualseries} type={currentChartType} height={365}  />
                 

                  </div>
                </div>
              </div>)

    }

    );

 

    setapiResponse(response);
            setDataisLoaded(true);
            setBarchartsatate(listItems);
            setreport_title(data_extract[0].Heading);

            
  }
  setBarchartsatate('');
  callbar();
  }, [props.countryID,props.resgionID])


  if (!DataisLoaded || !Barchartsatate) return(

      <div className="container-fluid" style={{'background': "#f0f3f6", 'text-align': "center" }}>
            <div className="row" style={{'display': "block", 'text-align': "center" }}>
            <div >
   <img style={{'height': '4rem' }} src="/myCSS/images/loader.gif" alt="image" className="profile-pic" /> </div>
            </div>
      </div>
    
   )
return (
 
    <div className="col-md-10 grid-margin">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row" >
              {Barchartsatate}
            </div>
          </div>
        
      </div>
    </div>
  



);
}


 

export default AllChartCompo;