import react from 'react'
const  Daterange =()=>{

    return(
      <div className="col-md-6">
        <div className="form-group" style={{"display": "inline-flex", "marginBottom": "0"}}>
          <div className="form-check" style={{"margin": "0 10px"}}>
            <select>
              <option>Select Year</option>
              <option>2021</option>
              <option>2022</option>
            </select>
          </div>
      
          <div className="form-check" style={{"margin": "0 10px"}}>
            <select>
              <option>Select Month</option>
              <option>Jan</option>
              <option>Feb</option>
              <option>Mar</option>
              <option>Apr</option>
              <option>May</option>
              <option>Jun</option>
              <option>Jul</option>
              <option>Aug</option>
              <option>Sept</option>
              <option>Oct</option>
              <option>Nov</option>
              <option>Dec</option>
            </select>
          </div>
          <div className="form-check" style={{"margin": "0 10px"}}>
            <select>
              <option>Select Week</option>
              <option>Week 1</option>
              <option>Week 2</option>
              <option>Week 2</option>
              <option>Week 4</option>
              <option>Week 5</option>
            </select>
          </div>
          <div className="form-check" style={{"margin": "0 10px"}}>
            <button type="button" className="btn btn-inverse-primary btn-sm" style={{"marginTop": "-4px"}}>Submit</button>
          </div>
        </div>
      </div>
      )
  }
export default Daterange;