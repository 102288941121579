import react, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import axios from 'axios';
import useToken from '../useToken';
import RegionCompo from '../RegionCompo'
import AuthenticateFailPage from '../AuthenticateFailPage'
import { GmipulseVerfyFirstRequest, GmipulseVerfySecondrequest } from "../../api-services/AllAPI";

function Nav(props) {
    const nav = useNavigate();

    const search = useLocation().search;
    const querystringParmenentToken = new URLSearchParams(search).get('token');
    const querystringTempToken = new URLSearchParams(search).get('temptoken');
    const querystringLid = new URLSearchParams(search).get('lid');
    const jwtoken = new URLSearchParams(search).get('jwttoken');
    const layout = new URLSearchParams(search).get('layout');
    const [sessionkey, setsessionkey] =react.useState('');

    let [token, setreport_id] = react.useState();


    var tokenString = sessionStorage.getItem("token");
    var layouttoken = sessionStorage.getItem("layout");
 
       
    if ((!tokenString && !querystringParmenentToken)) {

        //console.log(tokenString);


        let resgiondata_and_token_check = {
            "unautheticuser": "Session has been lost, please try again from your dashboard.",
            "resgiondata": ''
        }
        setdatawithtokenandresgiondata(resgiondata_and_token_check, layouttoken);



    } else if ((tokenString !== "" && tokenString !== null)) {
 
        let tokenarray = {
            
            "parmenettoken": sessionStorage.getItem("parmenettoken"),
            "jwttoken": JSON.parse(tokenString)

        };

        const fetchData = react.useCallback(async() => {

            let response = await GmipulseVerfySecondrequest(tokenarray)
            setdatawithtokenandresgiondata(response, layouttoken);

            
        }, [])
        react.useEffect(() => {
            fetchData()
        }, [fetchData])
    } else if (querystringParmenentToken !== "" && querystringParmenentToken !== null) {

       
        let tokenarray = {
            "lid": querystringLid,
            "parmenettoken": querystringParmenentToken,
            "temptoken": querystringTempToken,
            "jwttoken": jwtoken

        };
       console.log(tokenarray);

       const   fetchData  =  react.useCallback(async() => {

          let response = await GmipulseVerfyFirstRequest(tokenarray, jwtoken, querystringParmenentToken,layout)

          setdatawithtokenandresgiondata(response, layout);

        }, [])
        react.useEffect(() => {
            fetchData()
        }, [fetchData])

    }


    function setdatawithtokenandresgiondata(resgiondata_and_token_check, layouttoken) {

        props.onloadgetresgionandcountry(resgiondata_and_token_check, layouttoken);
    }



    function logoutsession() {


        sessionStorage.removeItem("token");
        nav("/");

    }

    function mydashboard(){

        window.open( 'https://gmipulse.gminsights.com/','_parent'); 
    }
    function myorder(){

        window.open('https://gmipulse.gminsights.com/admin/products','_parent'); 
    }

    function myaccount(){
        window.open('https://gmipulse.gminsights.com/admin/account','_parent');
        
    }

    return (

        <div>
            <nav className="navbar top-navbar col-lg-12 col-12 p-0">

                <div className="container-fluid">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                           <p> <a className="navbar-brand brand-logo" href="#"><img src="/myCSS/report/gmipulse.png" alt="logo" /></a>
                            <a className="navbar-brand brand-logo-mini" href="#"><img src="/myCSS/report/gmipulse.png" alt="logo" /> </a><p  style={{background: "#d2e0e6"}}>Beta Version 1.1</p></p>
                           
                           
                        </div>
                       
                    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
                        <ul className="navbar-nav navbar-nav-left" style={{ visibility: 'hidden' }}>
                            <li className="nav-item dropdown" >
                                <a className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
                                    <i className="mdi mdi-bell mx-0"></i>
                                    <span className="count bg-success">2</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown" >
                                    <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
                                    <a className="dropdown-item preview-item">
                                        <div className="preview-thumbnail">
                                            <div className="preview-icon bg-success">
                                                <i className="mdi mdi-information mx-0"></i>
                                            </div>
                                        </div>
                                        <div className="preview-item-content">
                                            <h6 className="preview-subject font-weight-normal">Application Error</h6>
                                            <p className="font-weight-light small-text mb-0 text-muted">
                                                Just now
          </p>
                                        </div>
                                    </a>
                                    <a className="dropdown-item preview-item">
                                        <div className="preview-thumbnail">
                                            <div className="preview-icon bg-warning">
                                                <i className="mdi mdi-settings mx-0"></i>
                                            </div>
                                        </div>
                                        <div className="preview-item-content">
                                            <h6 className="preview-subject font-weight-normal">Settings</h6>
                                            <p className="font-weight-light small-text mb-0 text-muted">
                                                Private message
          </p>
                                        </div>
                                    </a>
                                    <a className="dropdown-item preview-item">
                                        <div className="preview-thumbnail">
                                            <div className="preview-icon bg-info">
                                                <i className="mdi mdi-account-box mx-0"></i>
                                            </div>
                                        </div>
                                        <div className="preview-item-content">
                                            <h6 className="preview-subject font-weight-normal">New user registration</h6>
                                            <p className="font-weight-light small-text mb-0 text-muted">
                                                2 days ago
          </p>
                                        </div>
                                    </a>
                                </div>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center" id="messageDropdown" href="#" data-bs-toggle="dropdown">
                                    <i className="mdi mdi-email mx-0"></i>
                                    <span className="count bg-primary">4</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
                                    <p className="mb-0 font-weight-normal float-left dropdown-header">Messages</p>
                                    <a className="dropdown-item preview-item">
                                        <div className="preview-thumbnail">
                                            <img src="myCSS/images/faces/face4.jpg" alt="image" className="profile-pic" />
                                        </div>
                                        <div className="preview-item-content flex-grow">
                                            <h6 className="preview-subject ellipsis font-weight-normal">David Grey
                        </h6>
                                            <p className="font-weight-light small-text text-muted mb-0">
                                                The meeting is cancelled
                        </p>
                                        </div>
                                    </a>
                                    <a className="dropdown-item preview-item">
                                        <div className="preview-thumbnail">
                                            <img src="images/faces/face2.jpg" alt="image" className="profile-pic" />
                                        </div>
                                        <div className="preview-item-content flex-grow">
                                            <h6 className="preview-subject ellipsis font-weight-normal">Tim Cook
                        </h6>
                                            <p className="font-weight-light small-text text-muted mb-0">
                                                New product launch
                        </p>
                                        </div>
                                    </a>
                                    <a className="dropdown-item preview-item">
                                        <div className="preview-thumbnail">
                                            <img src="myCSS/images/faces/face3.jpg" alt="image" className="profile-pic" />
                                        </div>
                                        <div className="preview-item-content flex-grow">
                                            <h6 className="preview-subject ellipsis font-weight-normal"> Johnson
                        </h6>
                                            <p className="font-weight-light small-text text-muted mb-0">
                                                Upcoming board meeting
                        </p>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a href="#" className="nav-link count-indicator "><i className="mdi mdi-message-reply-text"></i></a>
                            </li>
                            <li className="nav-item nav-search d-none d-lg-block ms-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="search">
                                            <i className="mdi mdi-magnify"></i>
                                        </span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="search" aria-label="search" aria-describedby="search" />
                                </div>
                            </li>

                        </ul>
                       




                        <ul className="navbar-nav navbar-nav-right">
                            <li className="nav-item dropdown  d-lg-flex d-none" style={{ visibility: 'hidden' }}>
                                <button type="button" className="btn btn-inverse-primary btn-sm">My Products </button>
                            </li>
                            <li className="nav-item dropdown d-lg-flex d-none" style={{ visibility: 'hidden' }}>
                                <a className="dropdown-toggle show-dropdown-arrow btn btn-inverse-primary btn-sm" id="nreportDropdown" href="#" data-bs-toggle="dropdown">
                                    Reports
                  </a>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="nreportDropdown">
                                    <p className="mb-0 font-weight-medium float-left dropdown-header">Reports</p>
                                    <a className="dropdown-item">
                                        <i className="mdi mdi-file-pdf text-primary"></i>
                                        Pdf
                      </a>
                                    <a className="dropdown-item">
                                        <i className="mdi mdi-file-excel text-primary"></i>
                                        Exel
                      </a>
                                </div>
                            </li>
                            
                            <li className="nav-item dropdown d-lg-flex d-none">
                                <button type="button" className="btn btn-inverse-primary btn-sm" onClick={() => mydashboard()}>My Dashboard</button>
                            </li>
                            <li className="nav-item dropdown d-lg-flex d-none">
                                <button type="button" className="btn btn-inverse-primary btn-sm" onClick={() => myorder()}>My Orders</button>
                            </li>
                            <li className="nav-item dropdown d-lg-flex d-none">
                                <button type="button" className="btn btn-inverse-primary btn-sm" onClick={() => myaccount()}>My Account</button>
                            </li>
                            <li className="nav-item nav-profile dropdown" style={{ visibility: 'hidden' }}>
                                <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" id="profileDropdown">
                                    <span className="nav-profile-name">Vikky</span>
                                    <span className="online-status"></span>
                                    <img src="myCSS/images/faces/face28.png" alt="profile" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                                    <a className="dropdown-item">
                                        <i className="mdi mdi-settings text-primary"></i>
                                        Settings
                      </a>
                                    <a className="dropdown-item">
                                        <i className="mdi mdi-logout text-primary"></i>
                                        Logout
                      </a>
                                </div>
                            </li>
                        </ul>
                        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
                            <span className="mdi mdi-menu"></span>
                        </button>

                    </div>
                </div>
            </nav>

        </div>

    );
}

export default Nav;