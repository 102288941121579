import React from 'react'

function Footer (){

    return (

        <>
            <footer className="footer">
                <div className="footer-wrap">
                    <div className="d-sm-flex justify-content-center justify-content-sm-between">
                        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © Global Market Insights, Inc. 2022</span>
                    </div>
                </div>
            </footer>
            
        </>

    );


}

export default Footer