import ReactApexChart from 'react-apexcharts';
import React, { useState,useEffect } from 'react'
import Nav2 from './MyUICompo/Nav2'
import Nav from './MyUICompo/Nav'
import CagrBoxCompo from './CagrBoxCompo'
import TableCompo from './TableCompo'
import {charttype} from './ChartTypeJson'
import { getRegionDataByID, getCountryDataByID, getStateDataByID } from "../api-services/AllAPI";
import './AllChartCompo.css';
import {getchart} from "./ChartsTypes"
  
const AllChartCompo = (props) =>{

    let [apiResponse, setapiResponse] = useState([]);
    let [DataisLoaded, setDataisLoaded] = useState(false); 
    let [Barchartsatate, setBarchartsatate] = useState([]);
    let [report_title, setreport_title] = useState();
    let response;
    let reportname;

    let [checkdata, setcheckdata] = useState(true);

    reportname = sessionStorage.getItem("reportname"); 
     
  useEffect(  () => {
    async function  callbar() {

    setcheckdata(true);
              
   if(props.countryID){
       
        response = await getCountryDataByID(props.countryID);
       
       
   }else if(props.stateID){

    response = await getStateDataByID(props.stateID);
    
   }else{
        
       
          response =   await getRegionDataByID(props.resgionID);
         
   }


   

   //console.log(response);

    
   let data_extract='';
   let cagr_data='';
   let rtitle;



    if(props.countryID){
      data_extract = response.coutrydata;
      rtitle=response.coutrydata[0].Heading;
      
    }else if(props.stateID){
      data_extract = response.statedata;
      rtitle=response.statedata[0].Heading;

    }else{

      if(response._RegionData.length <= 0){

        setcheckdata(false);
      }
      data_extract = response._RegionData;
      cagr_data=response._RegionData[0];
      rtitle=cagr_data.Heading;
       
    }
       
   
    function getchartsdata(charttype){
      let ctype=   getchart(charttype);
    
  
      return ctype; 
    }
   
    let optionarray = [];
    let apexchartsarray = [];
    let currentChartType=[];
    let flag=0;

   
    let listItems = data_extract.map((item, index, dataarray) => {

       
      let count_array = dataarray[index].tabledata.length;
     
      let optionsString;
          let seriesString;
          let seriesdata;
          let currentChartType;
           let acyualseries;
           let titlenamefirst=dataarray[index].Heading.substr(0, 70).lastIndexOf(' ');
           let titlename=dataarray[index].Heading.substr(0, titlenamefirst);
           let subtitlename=dataarray[index].Heading.substr(titlenamefirst);

           let chartsdata =  getchartsdata(dataarray[index].chartTyape);

           
          
           chartsdata.series=dataarray[index].tabledata;
           chartsdata.options.title.text=titlename;
           chartsdata.options.subtitle.text=subtitlename;
           chartsdata.options.chart.toolbar.export.png.filename=dataarray[index].Heading;
           chartsdata.options.chart.toolbar.export.csv.filename=dataarray[index].Heading;
           chartsdata.options.xaxis.categories=dataarray[index].year; 
           optionarray=chartsdata.options;   
           seriesString= chartsdata.series;    
           currentChartType=chartsdata.options.chart.type;
           
            

           if(count_array=='1')
           { 
            seriesdata=seriesString;
           }else
           {seriesdata=seriesString.slice(0, count_array-1);}



           /* charttype.map((charttype, chartindex,chartArray) => {

              Object.entries(chartArray[chartindex]).map(([key,subindex])=>{
                
                
                if(dataarray[index].chartTyape==key){
                  //console.log(dataarray[index].chartTyape);
                   chartArray[chartindex][key].series=dataarray[index].tabledata;
                   //chartArray[chartindex][key].options.title.text=dataarray[index].Heading;
                   chartArray[chartindex][key].options.title.text=titlename;
                   chartArray[chartindex][key].options.subtitle.text=subtitlename;
                   chartArray[chartindex][key].options.chart.toolbar.export.png.filename=dataarray[index].Heading;
                   chartArray[chartindex][key].options.chart.toolbar.export.csv.filename=dataarray[index].Heading;
                  //console.log(chartArray[chartindex][key].series);
                  if(dataarray[index].chartTyape=="pie"){
                    chartArray[chartindex][key].options.labels=dataarray[index].year;
                    //console.log(chartArray[chartindex][key].options.labels);
                  }else{
                    chartArray[chartindex][key].options.xaxis.categories=dataarray[index].year; 
                  }
                  optionsString= chartArray[chartindex][key].options;
                  seriesString= chartArray[chartindex][key].series;
                  currentChartType=chartArray[chartindex][key].options.chart.type;
                  if(count_array=='1')
                  { 
                      acyualseries=seriesString;
                  }else
                  {acyualseries=seriesString.slice(0, count_array-1);}
                }
 
                
            })
            

            }); */

            return (
              <div className="col-sm-6 grid-margin grid-margin-md-0 stretch-card mt-2">
                <div className="card">
                  <div className="card-body">
                    
                  <p className="noselect" style={{color:'transparent'}}>{dataarray[index].Heading}</p>
                    <div id="support-tracker-legend" className="support-tracker-legend"></div>
   
                    <ReactApexChart options={optionarray} series={seriesdata} type={currentChartType} height={350} />
                  </div>
                </div>
              </div>)

    }

    );

 

    setapiResponse(response);
            setDataisLoaded(true);
            setBarchartsatate(listItems);
            setreport_title(data_extract[0].Heading);

            
  }
  setBarchartsatate('');
  callbar();
  }, [props.countryID,props.resgionID,props.stateID])

  if(!checkdata){
    return(
      <div className="col-md-10">
      <h3 style={{'display': "block", 'text-align': "center", 'margin-top': "20px" }}>Data not available for this region.</h3>
      <h3 style={{'display': "block", 'text-align': "center", 'margin-top': "20px" }}>Please select another region to get data.</h3>
      </div>
    )
}

  if (!DataisLoaded || !Barchartsatate) return(

      <div className="container-fluid" style={{'background': "#f0f3f6", 'text-align': "center" }}>
            <div className="row" style={{'display': "block", 'text-align': "center" }}>
            <div >
   <img style={{'height': '4rem' }} src="/myCSS/images/loader.gif" alt="image" className="profile-pic" /> </div>
            </div>
      </div>
    
   )
return (
  <div>

      
     
    <div className="mixed-chart">
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
          <p>{!reportname?"You are here":reportname}: {" "+props.breadcrumb} </p>
            <div className="row" >
              {Barchartsatate}
            </div>
          </div>
        </div>
      </div>
    </div>
     
  </div>




);
}


 

export default AllChartCompo;