import react from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

const StaticMenu = (props) => {


    return(
       <> 
       <nav className="bottom-navbar">
        <div className="container p-2">
          <div className="col-sm-6 mb-4 mb-xl-0" style={{visibility: 'hidden'}}>
            <div className="d-lg-flex align-items-center">
              <div>
                <h3 className="text-dark font-weight-bold mb-2">{/*props.report_name.substring(0, props.report_name.indexOf("Market")+6)*/}</h3>
                <h6 className="font-weight-normal mb-2">Healthcare & Medical Devices </h6>
              </div>

            </div>
          </div>

          <ul className="nav page-navigation">
            <li className={props.actualurl === "/" ? "active nav-item" : " nav-item"} id="1"  style={{visibility: 'hidden'}}>
              <Link className="nav-link" to="/"><i className="mdi mdi-file-document-box menu-icon"></i><span className="menu-title">Report Dashboard</span></Link>

            </li>
            
            
            <li className={props.urlpath[3] === "charts" ? "active nav-item" : " nav-item"} id="2">
            <Link className="nav-link" to={"/"+props.reportId+"/"+(props.resgionName? props.resgionName : "global" )+"/charts"+(props.isResgionset? '?resgionId='+props.resgionId : "" )}>
                <i className="mdi mdi-finance menu-icon"></i>
                <span className="menu-title">Charts</span>
                <i className="menu-arrow"></i>
              </Link>
            </li>
            <li className={props.urlpath[3] === "tables" ? "active nav-item" : " nav-item"} id="3">
              <Link className="nav-link" to={"/"+props.reportId+"/"+(props.resgionName? props.resgionName : "global" )+"/tables"+(props.isResgionset? '?resgionId='+props.resgionId : "" )}><i className="mdi mdi-grid menu-icon"></i><span className="menu-title">Tables</span> <i className="menu-arrow"></i></Link>

            </li>
          
 
            <li className={" nav-item"} style={{visibility: 'hidden'}}>
              <Link className="nav-link"  to={"/"+props.reportId+"/tables"+(props.isResgionset? '?resgionId='+props.resgionId : "" )}>
              <i className="mdi mdi-file-document-box-outline menu-icon"></i>
                <span className="menu-title">Reader PDF View</span> <i className="menu-arrow"></i></Link>

            </li>
          </ul>
        </div>
      </nav>
       </>
    )
}
 
export default StaticMenu;