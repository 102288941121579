import react from 'react'
import RegionDataCompo from './RegionDataCompo'

const MenuClickEvent = (props)=>{


    return(
        <RegionDataCompo rid={props.id} />
      
        );
}

export default MenuClickEvent;