import React from 'react';
import ChartCompo from './component/ChartCompo'
import Nav from './component/MyUICompo/Nav'
import Footer from './component/MyUICompo/Footer'
import TableCompo from './component/TableCompo'
import Nav2 from './component/MyUICompo/Nav2'


class App extends React.Component {
 


  render() {

    return(

     <>
       
     </>
    ) 
  }

}

export default App;
