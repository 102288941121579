
/*
    This API use for verify users login details
*/
import { wait } from '@testing-library/user-event/dist/utils';
import axios from 'axios';
import React, { useState } from 'react'
import AuthenticateFailPage  from '../component/AuthenticateFailPage';

export function loginUser(credentials) {
  

    return fetch('https://api.gmipulse.store/login', {
  
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data =>  data.json());
      //return "welcome";
   }

/*
    After user login user get one token every time when user login then set that token in to state
*/
export function matchtoken (token){

    return fetch('https://api.gmipulse.store/login/me', {
  
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'token': token
    }
  })
    .then(userdata =>  userdata.json());
  }

  /*
    This API call to get data of country by its ID
  */
  export async function getCountryDataByID(countryID) {

   /* let url;
    
    url="https://api.gmipulse.store/getsinglecountrybyID/"+countryID;
   
    let response = fetch(url)
      .then(res => res.json());

      console.log(response);

      return response;*/

      let datat='';

      var tokenString = sessionStorage.getItem("token");
      let tokenarray = {
        "jwttoken": JSON.parse(tokenString),
        "regionid":countryID

    };
    await axios({

        "method": "POST",

      "url": "https://api.gmipulse.store/getsinglecountrybyID/",

      "headers": {
        "Content-Type": "application/json",
        "apitoken": JSON.stringify(tokenarray)
    }, "params": {

        "language_code": "en"

      }

    })

    .then((response) => {

      //console.log(response.data);
      datat=response.data;
      if (datat.reportname !== undefined) {
        sessionStorage.setItem("reportname", datat.reportname);
      }
     

    })

    .catch((error) => {

      console.log(error)

    })
    if(datat!=""){
      return datat;
    }
   
  
  }

  /*
    This API call to get data of resgion by its ID
  */
  export async function getRegionDataByID(resgionID) {
   
 
     let datat='';
     let msg='';

      var tokenString = sessionStorage.getItem("token");
      let tokenarray = {
        "jwttoken": JSON.parse(tokenString),
        "regionid":resgionID

    };
    await axios({

        "method": "POST",

      "url": "https://api.gmipulse.store/getreport/",

      "headers": {
        "Content-Type": "application/json",
        "apitoken": JSON.stringify(tokenarray)
    }, "params": {

        "language_code": "en"

      }

    })

    .then((response) => {

      //console.log(response.data);

      if (response.data.message) {
         
        msg=response.data.message;
        
    } else {

      datat=response.data;
      if (datat.reportname !== undefined) {
        sessionStorage.setItem("reportname", datat.reportname);
      }
    
    }
     
     

    })

    .catch((error) => {

      console.log(error)

    })
    if(datat!=""){
      return datat;
    }else{

      return <AuthenticateFailPage message={msg}/>
    }
   
  }

  export async function GmipulseVerfyFirstRequest(tokenarray, tokenString, querystringParmenentToken,layout){

    let datat='';

    await axios({
      "method": "POST",
      "url": "https://api.gmipulse.store/gmipulse-verify-temp-newtoken",
      "headers": {
          "Content-Type": "application/json",
          "apitoken": JSON.stringify(tokenarray)
      },
      "params": {
          "language_code": "en"
      }

  })
      .then((response) => {

         console.log(response);
          let resgiondata_and_token_check = "";
          if (response.data.message) {
              resgiondata_and_token_check = {
                  "unautheticuser": response.data.message,
                  "resgiondata": ""
              }
          } else {

              resgiondata_and_token_check = {
                  "unautheticuser": "",
                  "resgiondata": response.data
              }
             
               
              //let sdata= + new Date();

              let jwttokenval =JSON.stringify(tokenString)
              
             sessionStorage.setItem("parmenettoken", querystringParmenentToken);
             sessionStorage.setItem("token", jwttokenval);
             sessionStorage.setItem("layout", layout);
              
             // alert("in aqury string");
          }

          datat=resgiondata_and_token_check;
          

          
      })
      .catch((error) => {
          console.log(error)
      })

      if(datat!=""){
        return datat;
      }

  }

  export async function GmipulseVerfySecondrequest(tokenarray){

    let responsedata;
   await axios({
      "method": "POST",
      "url": "https://api.gmipulse.store/check-gmi-pulse-token",
      "headers": {
          "Content-Type": "application/json",
          "apitoken": JSON.stringify(tokenarray)
      }, "params": {
          "language_code": "en"
      }

  })
      .then((response) => {

          let resgiondata_and_token_check = "";
          if (response.data.message) {
              resgiondata_and_token_check = {
                  "unautheticuser": response.data.message,
                  "resgiondata": ""
              }
          } else {

              resgiondata_and_token_check = {
                  "unautheticuser": "",
                  "resgiondata": response.data
              }
          }


          responsedata=resgiondata_and_token_check;
          



      })
      .catch((error) => {
          console.log(error)
      })

      if(responsedata!=""){
        return responsedata;
      }
  }

  export async function getStateNameByCountryId(countryID){
 
    let datat='';

    var tokenString = sessionStorage.getItem("token");
    let tokenarray = {
      "jwttoken": JSON.parse(tokenString),
      "countryid":countryID

  }; 
  await axios({

      "method": "POST",

    "url": "https://api.gmipulse.store/getStateDataBYcountryId/",

    "headers": {
      "Content-Type": "application/json",
      "apitoken": JSON.stringify(tokenarray)
  }, "params": {

      "language_code": "en"

    }

  })

  .then((response) => {

    //console.log(response.data);
    datat=response.data;
    if (datat.reportname !== undefined) {
      sessionStorage.setItem("reportname", datat.reportname);
    }
  

  })

  .catch((error) => {

    console.log(error)

  })
  if(datat!=""){
    return datat;
  }


}

export async function getStateDataByID(stateId){
  let datat='';

  var tokenString = sessionStorage.getItem("token");
  let tokenarray = {
    "jwttoken": JSON.parse(tokenString),
    "Stateid":stateId

}; 
await axios({

    "method": "POST",

  "url": "https://api.gmipulse.store/getSingleStateDataByStateId/",

  "headers": {
    "Content-Type": "application/json",
    "apitoken": JSON.stringify(tokenarray)
}, "params": {

    "language_code": "en"

  }

})

.then((response) => {

  //console.log(response.data);
  datat=response.data;
   


})

.catch((error) => {

  console.log(error)

})
if(datat!=""){
  return datat;
}



}

 