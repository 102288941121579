import react, {useState} from 'react'
import axios from 'axios';
import {BrowserRouter as Router,Routes,Route, Navigate ,Link, useLocation} from 'react-router-dom';

import Nav from './Nav'
 
import TableCompo from '../TableCompo';
import ChartCompo from '../ChartCompo';
import Footer from './Footer';
import RegionDataCompo from '../RegionDataCompo';
 
import RegionCompo from '../RegionCompo'
import AllChartCompo from '../AllChartCompo'
import AllTableCompo from '../AllTableCompo'
import Chart from '../Chart'
import Login from '../Login'

import CountryChart from '../CountryChart'
import PageNotFound from '../PageNotFound'
import useToken from '../useToken';

import AuthenticateFailPage from '../AuthenticateFailPage'
import SideMenuBar from '../SideMenuBar'
import SideMenuBarDemo2 from '../../DemoComponent1/SideMenuBarDemo2'
import SideMenuBarDemo from '../../DemoComponent/SideMenuBarDemo'
import SideMenuBarDemo3 from '../../DemoComponentABBClient/SideMenuBarDemo3'

import SideMenuBarDemo4 from '../../DemoComponentCryoSpain/SideMenuBarDemo4'
import Logout from '../Logout'


 const Nav2 = () => {

 

  const regionchangehandler = (rID) => {

   setrid(rID.resgion_id);
  // setreport_id(rID.report_id);
   setcheckloaddatan(true);
 
  } 
 
let [authentication, setauthentication] = react.useState('');
let [_rid, setrid] = react.useState('');
let [report_id, setreport_id] = react.useState('');
let [checkloaddata, setcheckloaddatan] = react.useState(false);
let [apiResponse, setapiResponse] = react.useState([]);
let [checklayout, setchecklayout] = react.useState('');

 /*
 const fetchData = react.useCallback(() => {
        axios({
          "method": "POST",
          "url": "http://137.184.238.167:9000/check-gmi-pulse-token",
          "headers": {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'apitoken': "asdasd"
          }, "params": {
            "language_code": "en"
          }
          
        })
        .then((response) => {
            setapiResponse(response.data);
            
            
        })
        .catch((error) => {
          console.log(error)
        })
      }, [])
      react.useEffect(() => {
        fetchData()
      }, [fetchData])*/



const routingdata = (resgionarray, layout) => {

  
if(resgionarray.unautheticuser!==""){

  //console.log(resgionarray.unautheticuser);
  setauthentication(resgionarray.unautheticuser);
   
    
 

}else if(resgionarray.resgiondata.length > 0){

  //console.log(resgionarray.resgiondata);
 
  setreport_id(resgionarray.resgiondata[0].reportid);
  setapiResponse(resgionarray.resgiondata);

}

if(layout.length > 0 && layout !=="null"){
  
   
  setchecklayout(layout);
} 
//setreport_id(resgionarray[0].reportid);
//setapiResponse(resgionarray);

 
}

if(authentication!==""){
  return <AuthenticateFailPage message={authentication}/>
}  
  return (
 
    <div>
    <Router>
     
    <Nav onloadgetresgionandcountry={routingdata} />
    
  
      
  <Routes>
   
  <Route exact  path="/logout" element={<Logout />} />

           

      {checklayout.length >0 && checklayout==="demo"? 
                <Route exact  path="/" element={<SideMenuBarDemo rid={report_id} ctype="alldata" name="asia-pacific" regiondata={apiResponse} />} /> 
                : checklayout.length >0 && checklayout==="demo1"? <Route exact  path="/" element={<SideMenuBarDemo2 rid={report_id} ctype="alldata" name="asia-pacific" regiondata={apiResponse} />} /> :checklayout.length >0 && checklayout==="demo3"? <Route exact  path="/" element={<SideMenuBarDemo3 rid={report_id} ctype="charts" name="north-america" regiondata={apiResponse} />} />  :checklayout.length >0 && checklayout==="demo4"? <Route exact  path="/" element={<SideMenuBarDemo4 rid={report_id} ctype="charts" name="north-america" regiondata={apiResponse} />} />:
                <Route exact  path="/" element={<SideMenuBar  rid={report_id} ctype="charts" name="global" regiondata={apiResponse} />} />

          }
          
           
            {

               
checklayout.length ===0 && apiResponse.length > 0  && apiResponse.map((dataarray) => {
            let countryResult =[];
            
            if(dataarray.mapping.length > 0 ){
                dataarray.mapping.map((countrymenu,index) =>{
                  countryResult[index]=
               <> <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/"+countrymenu.cname.replace(/\s+/g, '-').toLowerCase()}  element={<CountryChart countryID={countrymenu._id} reportID={dataarray.reportid} name={dataarray.name+'/'+countrymenu.cname} />}/>
                <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/"+countrymenu.cname.replace(/\s+/g, '-').toLowerCase()+"/charts"}  element={<SideMenuBar countryID={countrymenu._id} regiondata={apiResponse} ctype="charts" regionId={dataarray._id} reportID={dataarray.reportid}name={dataarray.name} cname={countrymenu.cname} />}/>
                <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/"+countrymenu.cname.replace(/\s+/g, '-').toLowerCase()+"/tables"}  element={<SideMenuBar countryID={countrymenu._id} regiondata={apiResponse} ctype="tables" reportID={dataarray.reportid} name={dataarray.name} regionId={dataarray._id} cname={countrymenu.cname} />}/></>
               
                
                
                 
            
            }
              )
            }
      return (
          <>
           {countryResult.length >0?countryResult : '' }
          <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()} element={<RegionDataCompo  resgionID={dataarray._id} reportID={dataarray.reportid} name={dataarray.name}/>} />
          <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/charts"}  element={<SideMenuBar ctype="charts" resgionID={dataarray._id} reportID={dataarray.reportid} name={dataarray.name} regiondata={apiResponse}/>}/>
          <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/tables"}  element={<SideMenuBar ctype="tables"  regiondata={apiResponse} resgionID={dataarray._id} reportID={dataarray.reportid} name={dataarray.name} />}/>
          </>
        )
        } 
        
        )


        
}

{

               
checklayout.length > 0 && apiResponse.length > 0  && apiResponse.map((dataarray) => {
            let countryResult =[];
            if(dataarray.mapping.length > 0 ){
                dataarray.mapping.map((countrymenu,index) =>{
                  countryResult[index]=
               <> <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/"+countrymenu.cname.replace(/\s+/g, '-').toLowerCase()}  element={<CountryChart countryID={countrymenu._id} reportID={dataarray.reportid} name={dataarray.name+'/'+countrymenu.cname} />}/>
                <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/"+countrymenu.cname.replace(/\s+/g, '-').toLowerCase()+"/charts"}  element={<SideMenuBarDemo countryID={countrymenu._id} regiondata={apiResponse} ctype="charts" regionId={dataarray._id} reportID={dataarray.reportid}name={dataarray.name} cname={countrymenu.cname} />}/>
                <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/"+countrymenu.cname.replace(/\s+/g, '-').toLowerCase()+"/tables"}  element={<SideMenuBarDemo countryID={countrymenu._id} regiondata={apiResponse} ctype="tables" reportID={dataarray.reportid} name={dataarray.name} regionId={dataarray._id} cname={countrymenu.cname} />}/>
                <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/"+countrymenu.cname.replace(/\s+/g, '-').toLowerCase()+"/alldata"}  element={<SideMenuBarDemo countryID={countrymenu._id} regiondata={apiResponse} ctype="alldata" reportID={dataarray.reportid} name={dataarray.name} regionId={dataarray._id} cname={countrymenu.cname} />}/>
                </>
               
                
                
                 
            
            }
              )
            }
      return (
          <>
           {countryResult.length >0?countryResult : '' }
          <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()} element={<RegionDataCompo  resgionID={dataarray._id} reportID={dataarray.reportid} name={dataarray.name}/>} />
          <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/charts"}  element={<SideMenuBarDemo ctype="charts" resgionID={dataarray._id} reportID={dataarray.reportid} name={dataarray.name} regiondata={apiResponse}/>}/>
          <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/tables"}  element={<SideMenuBarDemo ctype="tables"  regiondata={apiResponse} resgionID={dataarray._id} reportID={dataarray.reportid} name={dataarray.name} />}/>
          <Route path={"/:id/"+dataarray.name.replace(/\s+/g, '-').toLowerCase()+"/alldata"}  element={<SideMenuBarDemo ctype="alldata"  regiondata={apiResponse} resgionID={dataarray._id} reportID={dataarray.reportid} name={dataarray.name} />}/>
          </>
        )
        } 
        
        )


        
}
        
          
</Routes>

<Footer />
</Router>
      </div>
      


  );
 }

 
export default Nav2;