import ReactApexChart from 'react-apexcharts';
import react, { useState, useEffect } from 'react'
import Nav2 from './MyUICompo/Nav2'
import { getRegionDataByID, getCountryDataByID,getStateDataByID } from "../api-services/AllAPI";
 

const AllTableCompo = (props) =>{

    let [apiResponse, setapiResponse] = useState([]);
  let [DataisLoaded, setDataisLoaded] = useState(false); 
  let [Barchartsatate, setBarchartsatate] = useState([]);
  let [report_title, setreport_title] = useState();

  let [checkdata, setcheckdata] = useState(true);
  let reportname;
  reportname = sessionStorage.getItem("reportname"); 
  
 
      
    useEffect(  () => {
 
        async function callbar() {
            setcheckdata(true);
               let response;
              
                if(props.countryID){
                    
                     response = await getCountryDataByID(props.countryID);
                    
                }else if(props.stateID){

                    response = await getStateDataByID(props.stateID);
                    
                }else{
                     response = await getRegionDataByID(props.resgionID);
                    
                }
            
           let data_extract='';
           if(props.countryID){
             data_extract = response.coutrydata;
             
           }else if(props.stateID){
            data_extract = response.statedata;
             

           }else{
            if(response._RegionData.length <= 0){

                setcheckdata(false);
              }
             data_extract = response._RegionData;
           }
         
          
        
           
            // console.log(data_extract);
        
        
        
            // return ( <ReactApexChart options={barchart1.options} series={barchart1.series} type="bar" height={350} />);
        
        
            let listItems = data_extract.map((item, index, dataarray) => {
        
               
                 
                return (
                    <div className="col-sm-12 grid-margin grid-margin-md-0 stretch-card mt-2">
                        <div className="card">
                            <div className="card-body table-responsive" >
                                <div className="d-flex align-items-center justify-content-between">
                                    <h3 >{dataarray[index].Heading}</h3>
                                     
                                </div>
        
                                <div id="support-tracker-legend" className="support-tracker-legend"></div>
                                <table class="table table-striped ">
                                    <thead>
                                        <tr>
                                            <th><b>Component</b></th>
                                            {dataarray[index].year.map(test => <td><b>{test}</b></td>)}
                                            <th><b>CAGR</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                   
                                                                               
                                              
                                                
                                     
                                         {dataarray[index].tabledata.map((tblmap,idx,tblarray) =>{   
                                                
                                                return(
                                            <tr> 
        
                                            
                                            <td><b>{tblarray[idx].name}</b></td>
                                            {tblarray[idx].data.map(maindata => <td>{tblarray[idx].name==="Total"?  <b>{ maindata.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </b>: maindata.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</td>)}
                                            <td>{tblarray[idx].cagr}</td>
                                            </tr>)
                                            }
                                            )}
                                            
                                     
                                         
                                    </tbody>
                                </table>
        
                               
                            </div>
                        </div>
                    </div>)
        
        
        
            }
        
            );
        
        
            
            setapiResponse(response);
            setDataisLoaded(true);
            setBarchartsatate(listItems);

            console.log(data_extract[0].Heading);
            setreport_title(data_extract[0].Heading);
            
        }
       callbar();
       setBarchartsatate('');
      
      
    }, [props.resgionID,props.countryID,props.stateID])

    if(!checkdata){
        return(
          <div className="col-md-10">
          <h3 style={{'display': "block", 'text-align': "center", 'margin-top': "20px" }}>Data not available for this region.</h3>
          <h3 style={{'display': "block", 'text-align': "center", 'margin-top': "20px" }}>Please select another region to get data.</h3>
          </div>
        )
    }

if (!DataisLoaded || !Barchartsatate)  return( <div className="container-fluid" style={{'background': "#f0f3f6", 'text-align': "center" }}>
<div className="row" style={{'display': "block", 'text-align': "center" }}>
<div >
<img style={{'height': '4rem' }} src="/myCSS/images/loader.gif" alt="image" className="profile-pic" /> </div>
</div>
</div>)
return (
    <div class="col-md-10">
                 
                <div className="mixed-chart">
                    <div className="container-fluid page-body-wrapper">
                        <div className="main-panel">
                            <div className="content-wrapper">
                           
                            <p>{!reportname?"You are here":reportname}: {" "+props.breadcrumb} </p>
                                <div className="row">
                                    {Barchartsatate}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


);




}

 


export default AllTableCompo;